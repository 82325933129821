import {Navbar,Nav,Container, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";

import { useState } from "react";

const Fakebar = () => {
  
  const changeColorblue = () => {
    const btn = document.getElementById('btn');
    if (btn.style.backgroundColor === 'salmon') {
      btn.style.backgroundColor = '#74a3a3';
      btn.style.color = 'black';
    } else {
      btn.style.backgroundColor = 'salmon';
      btn.style.color = 'white';
     
    }
   
  }

  const navigate = useNavigate();


  const [accesslocation, setAccessLocation] = useState(0);
  const [accesslocation2, setAccessLocation2] = useState(0);
  
  const successCallback = (position) => {
      console.log(position);
      setAccessLocation(position.coords.latitude);
      setAccessLocation2(position.coords.longitude);
      console.log(accesslocation);
      console.log(accesslocation2);

  }
  const errorCallback = (error) => {
      console.error(error);
  };

  navigator.geolocation.watchPosition(successCallback, errorCallback);
  
  const locationlock = () => {
  
    if (((accesslocation >  43.0507 && accesslocation < 43.0538 && Math.abs(accesslocation2) < 76.1553 && Math.abs(accesslocation2) > 76.1130) 
    ||  (accesslocation >  43.0460 && accesslocation < 43.0486 && Math.abs(accesslocation2) < 76.1175 && Math.abs(accesslocation2) > 76.0850))) {
         console.log('welcome')
      console.log(accesslocation)
      console.log(Math.abs(accesslocation2))
      
    } else {
        navigate('/locationlock')
console.log('what?')
console.log(accesslocation);
console.log(accesslocation2);


    }

  }


    return (
      <div className="App3">
      <nav>
        
<Navbar collapseOnSelect expand="lg" bg="" variant="dark">

  <Container>
  
  <Navbar.Brand as={Link} to={"./map"}>Recipe Lab</Navbar.Brand>
  
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="me-auto">
      {/*<Nav.Link as={Link} to={"./menus"}>Features</Nav.Link>*/}
     {/* <NavDropdown title="Store Inventories" id="collasible-nav-dropdown">
     
      <NavDropdown.Item as={Link} to={"./wgmsjames"}>
        Wegmans James St.
      </NavDropdown.Item>
      <NavDropdown.Item as={Link} to={"./pcerie"}>
        Price Chopper Erie blvd.
      </NavDropdown.Item>
  
 
    
  </NavDropdown>*/}
  <NavDropdown title="Buisness Accounts" id="collasible-nav-dropdown">
     
      <NavDropdown.Item as={Link} onClick={locationlock} to={"./apizza"}>
        APIZZA REGINALE
      </NavDropdown.Item> 
  </NavDropdown>
  <Nav.Link as={Link} to={"./map"}>
        MAP
      </Nav.Link>
  <Nav.Link as={Link} to={"./recipesearch"}>
        RECIPE SEARCH
      </Nav.Link>
    <Nav.Link as={Link} to={"./recipes"}>
        RECIPE BOOK
      </Nav.Link>
      
    </Nav>
    <Nav>
    <button className="buttont" onClick = {changeColorblue}>Style</button>
     {/* <Nav.Link href="https://www.instagram.com/explore/locations/663473593807965/scratch-farmhouse-catering/"><i class="bi bi-instagram"></i></Nav.Link>
      <Nav.Link href="https://www.facebook.com/scratchfarmhousecatering/">
        <i class="bi bi-facebook"></i>
    </Nav.Link>*/}
    </Nav>
  </Navbar.Collapse>
  </Container>
</Navbar>
</nav>
      
      </div>
    );
}

export default Fakebar;