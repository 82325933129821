import { Outlet } from "react-router-dom"
import Fakebar from "../routes/navholder"

const Layout = () => {
    return (
        <main >
            <Fakebar />
            <Outlet className="App" />
        </main>
    )
}

export default Layout
