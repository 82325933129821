import { useState, useCallback } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";


const useRecipeutils =()=>{
    
    const [recipiename, setRecipiename] = useState("");
    const [recipieslist, setRecipieslist] = useState([]);
    const [measurement1, setMeasurement1] = useState("cups");
    const [measurement2, setMeasurement2] = useState("cups");
    const [measurement3, setMeasurement3] = useState("cups");
    const [measurement4, setMeasurement4] = useState("cups");
    const [measurement5, setMeasurement5] = useState("cups");
    const [measurement6, setMeasurement6] = useState("cups");
    const [measurement7, setMeasurement7] = useState("cups");
    const [measurement8, setMeasurement8] = useState("cups");
    const [measurement9, setMeasurement9] = useState("cups");
    const [measurement10, setMeasurement10] = useState("cups");
    const [measurement11, setMeasurement11] = useState("cups");
    const [measurement12, setMeasurement12] = useState("cups");
    const [ingediant1, setIngediant1] = useState("");
    const [ingediant2, setIngediant2] = useState("");
    const [ingediant3, setIngediant3] = useState("");
    const [ingediant4, setIngediant4] = useState("");
    const [ingediant5, setIngediant5] = useState("");
    const [ingediant6, setIngediant6] = useState("");
    const [ingediant7, setIngediant7] = useState("");
    const [ingediant8, setIngediant8] = useState("");
    const [ingediant9, setIngediant9] = useState("");
    const [ingediant10, setIngediant10] = useState("");
    const [ingediant11, setIngediant11] = useState("");
    const [ingediant12, setIngediant12] = useState("");
    const [ingediant1num, setIngediant1num] = useState(0);
  const [ingediant2num, setIngediant2num] = useState(0);
  const [ingediant3num, setIngediant3num] = useState(0);
  const [ingediant4num, setIngediant4num] = useState(0);
  const [ingediant5num, setIngediant5num] = useState(0);
  const [ingediant6num, setIngediant6num] = useState(0);
  const [ingediant7num, setIngediant7num] = useState(0);
  const [ingediant8num, setIngediant8num] = useState(0);
  const [ingediant9num, setIngediant9num] = useState(0);
  const [ingediant10num, setIngediant10num] = useState(0);
  const [ingediant11num, setIngediant11num] = useState(0);
  const [ingediant12num, setIngediant12num] = useState(0);
const [searchTerm, setSearchTerm] = useState("")
  const [notes, setNotes] = useState("");
  const [newnotes, setNewnotes] = useState("");
  const [newrecipiename, setNewrecipiename] = useState("");
  const [times,setTimes] = useState(0);

    const axiosPrivate = useAxiosPrivate();

    

    const [buildname, setBuildname] = useState("");
    const [buildpropslist, setBuildpropslist] = useState([]);
    const [buildId, setBuildId] = useState(0);
    const [buildDescription, setBuildDescription] = useState("");
    const [buildimagepath,setBuildimagepath] = useState("");
    const [buildimagepath2, setBuildimagepath2] = useState("");
    const [buildprops, setBuildprops] = useState("")


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
   

    
    const addRecipieprops = async () => {
  
      await axiosPrivate.post("recipies/buildprops", {
        mode: 'cors',
        buildname: buildname,
        buildId: buildId,
        buildDescription: buildDescription,
        buildimagepath: buildimagepath,
        buildimagepath2: buildimagepath2,
          
      }).then(() => {
        setBuildpropslist([
          ...buildpropslist,
          {
            mode: 'cors',
            buildname: buildname,
            buildId: buildId,
            buildDescription: buildDescription,
            buildimagepath: buildimagepath,
            buildimagepath2: buildimagepath2,
             
            
          },
        ]);
        handleClose();
      });
    };
    
    const addImglink = async () => {
  
      await axiosPrivate.post("recipies/buildprops/imglink", {
        mode: 'cors',
  
        buildId: buildId,
      
        buildimagepath: buildimagepath,
        
          
      }).then(() => {
        setBuildpropslist([
          ...buildpropslist,
          {
            mode: 'cors',
            buildname: buildname,
            buildId: buildId,
            buildDescription: buildDescription,
            buildimagepath: buildimagepath,
            buildimagepath2: buildimagepath2,
             
            
          },
        ]);
        handleClose();
      });
    };
  
     
    const getBuildprops = useCallback( async (buildimagepath) => {
        
      // locationlock();
      
 await axiosPrivate.get(`/recipies/buildprops/${buildimagepath}`,  {
           buildimagepath: buildimagepath,
         }).then((response) => {
          console.log(response.data);

           setBuildprops(response.data);
           setBuildname(response.data[0].buildname);
           setBuildId(response.data[0].buildId);
           setBuildDescription(response.data[0].buildDescription);
           setBuildimagepath(response.data[0].buildimagepath);
           setBuildimagepath2(response.data[0].buildimagepath2);
           window.scrollTo(0, 1000);
         }
         )
     },[axiosPrivate])
     

    const getRecipies = async  (url) => {
        await axiosPrivate.get(url).then((response) => {
            
           setRecipieslist(response.data);
           window.scrollTo(0, 1000);
         }, [url]);
       };
       /*const getRecipies = async  () => {
        // locationlock();
        await axiosPrivate.get("/recipies").then((response) => {
           setRecipieslist(response.data);
           window.scrollTo(0, 1000);
         });
       };*/

       
       const resetIngediantNumber = async (id) => {
        await axiosPrivate.put("recipies/recipenotes", {
          mode: 'cors',
          times: times,
          id: id,
        }).then(
          (response) => {
            setRecipieslist(recipieslist.map((val, key) => {
              return val.id === id ? {
                recipiename: val.recipiename,
                id: val.id,
                measurement1: val.measurement1,
                measurement2: val.measurement2,
                measurement3: val.measurement3,
                measurement4: val.measurement4,
                measurement5: val.measurement5,
                measurement6: val.measurement6,
                measurement7: val.measurement7,
                measurement8: val.measurement8,
                measurement9: val.measurement9,
                measurement10: val.measurement10,
                measurement11: val.measurement11,
                measurement12: val.measurement12,
                ingediant1: val.ingediant1,
                ingediant2: val.ingediant2,
                ingediant3: val.ingediant3,
                ingediant4: val.ingediant4,
                ingediant5: val.ingediant5,
                ingediant6: val.ingediant6,
                ingediant7: val.ingediant7,
                ingediant8: val.ingediant8,
                ingediant9: val.ingediant9,
                ingediant10: val.ingediant10,
                ingediant11: val.ingediant11,
                ingediant12: val.ingediant12,
                ingediant1num: val.ingediant1num/times,
                ingediant2num: val.ingediant2num/times,
                ingediant3num: val.ingediant3num/times,
                ingediant4num: val.ingediant4num/times,
                ingediant5num: val.ingediant5num/times,
                ingediant6num: val.ingediant6num/times,
                ingediant7num: val.ingediant7num/times,
                ingediant8num: val.ingediant8num/times,
                ingediant9num: val.ingediant9num/times,
                ingediant10num: val.ingediant10num/times,
                ingediant11num: val.ingediant11num/times,
                ingediant12num: val.ingediant12num/times,
                notes: val.notes
              } : val
            }));
          }
        );
      };
    

      const updateRecipiename = async (id) => {
        await axiosPrivate.put("recipies/recipename", {
          mode: 'cors',
          recipiename: newrecipiename,
          id: id,
        }).then(
          (response) => {
            setRecipieslist(recipieslist.map((val) => {
              return val.id === id ? {
                recipiename: newrecipiename,
                id: val.id,
                measurement1: val.measurement1,
                measurement2: val.measurement2,
                measurement3: val.measurement3,
                measurement4: val.measurement4,
                measurement5: val.measurement5,
                measurement6: val.measurement6,
                measurement7: val.measurement7,
                measurement8: val.measurement8,
                measurement9: val.measurement9,
                measurement10: val.measurement10,
                measurement11: val.measurement11,
                measurement12: val.measurement12,
                ingediant1: val.ingediant1,
                ingediant2: val.ingediant2,
                ingediant3: val.ingediant3,
                ingediant4: val.ingediant4,
                ingediant5: val.ingediant5,
                ingediant6: val.ingediant6,
                ingediant7: val.ingediant7,
                ingediant8: val.ingediant8,
                ingediant9: val.ingediant9,
                ingediant10: val.ingediant10,
                ingediant11: val.ingediant11,
                ingediant12: val.ingediant12,
                ingediant1num: val.ingediant1num,
                ingediant2num: val.ingediant2num,
                ingediant3num: val.ingediant3num,
                ingediant4num: val.ingediant4num,
                ingediant5num: val.ingediant5num,
                ingediant6num: val.ingediant6num,
                ingediant7num: val.ingediant7num,
                ingediant8num: val.ingediant8num,
                ingediant9num: val.ingediant9num,
                ingediant10num: val.ingediant10num,
                ingediant11num: val.ingediant11num,
                ingediant12num: val.ingediant12num,
                notes: val.notes
              } : val
            }));
          }
        );
      };
    






      const updateNotes = async (id) => {
        await axiosPrivate.put("recipies/recipenotes", {
          mode: 'cors',
          notes: newnotes,
          id: id,
        }).then(
          (response) => {
            setRecipieslist(recipieslist.map((val, key) => {
              return val.id === id ? {
                recipiename: val.recipiename,
                id: val.id,
                measurement1: val.measurement1,
                measurement2: val.measurement2,
                measurement3: val.measurement3,
                measurement4: val.measurement4,
                measurement5: val.measurement5,
                measurement6: val.measurement6,
                measurement7: val.measurement7,
                measurement8: val.measurement8,
                measurement9: val.measurement9,
                measurement10: val.measurement10,
                measurement11: val.measurement11,
                measurement12: val.measurement12,
                ingediant1: val.ingediant1,
                ingediant2: val.ingediant2,
                ingediant3: val.ingediant3,
                ingediant4: val.ingediant4,
                ingediant5: val.ingediant5,
                ingediant6: val.ingediant6,
                ingediant7: val.ingediant7,
                ingediant8: val.ingediant8,
                ingediant9: val.ingediant9,
                ingediant10: val.ingediant10,
                ingediant11: val.ingediant11,
                ingediant12: val.ingediant12,
                ingediant1num: val.ingediant1num,
                ingediant2num: val.ingediant2num,
                ingediant3num: val.ingediant3num,
                ingediant4num: val.ingediant4num,
                ingediant5num: val.ingediant5num,
                ingediant6num: val.ingediant6num,
                ingediant7num: val.ingediant7num,
                ingediant8num: val.ingediant8num,
                ingediant9num: val.ingediant9num,
                ingediant10num: val.ingediant10num,
                ingediant11num: val.ingediant11num,
                ingediant12num: val.ingediant12num,
                notes: newnotes
              } : val
            }));
          }
        );
      };


  const newIngediantNumber = async (id) => {
    await axiosPrivate.put("recipies/recipenotes", {
      mode: 'cors',
      times: times,
      id: id,
    }).then(
      (response) => {
        setRecipieslist(recipieslist.map((val, key) => {
          return val.id === id ? {
            recipiename: val.recipiename,
            id: val.id,
            measurement1: val.measurement1,
            measurement2: val.measurement2,
            measurement3: val.measurement3,
            measurement4: val.measurement4,
            measurement5: val.measurement5,
            measurement6: val.measurement6,
            measurement7: val.measurement7,
            measurement8: val.measurement8,
            measurement9: val.measurement9,
            measurement10: val.measurement10,
            measurement11: val.measurement11,
            measurement12: val.measurement12,
            ingediant1: val.ingediant1,
            ingediant2: val.ingediant2,
            ingediant3: val.ingediant3,
            ingediant4: val.ingediant4,
            ingediant5: val.ingediant5,
            ingediant6: val.ingediant6,
            ingediant7: val.ingediant7,
            ingediant8: val.ingediant8,
            ingediant9: val.ingediant9,
            ingediant10: val.ingediant10,
            ingediant11: val.ingediant11,
            ingediant12: val.ingediant12,
            ingediant1num: val.ingediant1num*times,
            ingediant2num: val.ingediant2num*times,
            ingediant3num: val.ingediant3num*times,
            ingediant4num: val.ingediant4num*times,
            ingediant5num: val.ingediant5num*times,
            ingediant6num: val.ingediant6num*times,
            ingediant7num: val.ingediant7num*times,
            ingediant8num: val.ingediant8num*times,
            ingediant9num: val.ingediant9num*times,
            ingediant10num: val.ingediant10num*times,
            ingediant11num: val.ingediant11num*times,
            ingediant12num: val.ingediant12num*times,
            notes: val.notes
          } : val
        }));
      }
    );
  };


    
  

  const addRecipie = async () => {
    await axiosPrivate.post("/recipies", {
      mode: 'cors',
      recipiename: recipiename,
      measurement1: measurement1,
      measurement2: measurement2,
      measurement3: measurement3,
      measurement4: measurement4,
      measurement5: measurement5,
      measurement6: measurement6,
      measurement7: measurement7,
      measurement8: measurement8,
      measurement9: measurement9,
      measurement10: measurement10,
      measurement11: measurement11,
      measurement12: measurement12,
      ingediant1: ingediant1,
      ingediant2: ingediant2,
      ingediant3: ingediant3,
      ingediant4: ingediant4,
      ingediant5: ingediant5,
      ingediant6: ingediant6,
      ingediant7: ingediant7,
      ingediant8: ingediant8,
      ingediant9: ingediant9,
      ingediant10: ingediant10,
      ingediant11: ingediant11,
      ingediant12: ingediant12,
      ingediant1num: ingediant1num,
      ingediant2num: ingediant2num,
      ingediant3num: ingediant3num,
      ingediant4num: ingediant4num,
      ingediant5num: ingediant5num,
      ingediant6num: ingediant6num,
      ingediant7num: ingediant7num,
      ingediant8num: ingediant8num,
      ingediant9num: ingediant9num,
      ingediant10num: ingediant10num,
      ingediant11num: ingediant11num,
      ingediant12num: ingediant12num,
      notes: notes
    }).then(() => {
      setRecipieslist([
        ...recipieslist,
        {
          mode: 'cors',
          recipiename: recipiename,
          measurement1: measurement1,
          measurement2: measurement2,
          measurement3: measurement3,
          measurement4: measurement4,
          measurement5: measurement5,
          measurement6: measurement6,
          measurement7: measurement7,
          measurement8: measurement8,
          measurement9: measurement9,
          measurement10: measurement10,
          measurement11: measurement11,
          measurement12: measurement12,
          ingediant1: ingediant1,
          ingediant2: ingediant2,
          ingediant3: ingediant3,
          ingediant4: ingediant4,
          ingediant5: ingediant5,
          ingediant6: ingediant6,
          ingediant7: ingediant7,
          ingediant8: ingediant8,
          ingediant9: ingediant9,
          ingediant10: ingediant10,
          ingediant11: ingediant11,
          ingediant12: ingediant12,
          ingediant1num: ingediant1num,
          ingediant2num: ingediant2num,
          ingediant3num: ingediant3num,
          ingediant4num: ingediant4num,
          ingediant5num: ingediant5num,
          ingediant6num: ingediant6num,
          ingediant7num: ingediant7num,
          ingediant8num: ingediant8num,
          ingediant9num: ingediant9num,
          ingediant10num: ingediant10num,
          ingediant11num: ingediant11num,
          ingediant12num: ingediant12num,
          notes: notes
        },
      ]);
    });
  };
  
  
  const confirmDelete = async (id) => {
    const confirmAction = window.confirm("Are you sure to execute this action?");
    if (confirmAction) {


      alert("Action successfully executed");
      await axiosPrivate.delete(`/recipies/${id}`, {
        id: id,
      }).then((response) => {
        setRecipieslist(
          recipieslist.filter((val) => {
            return val.id !== id;
          }));
      }
      );


    } else {
      alert("Action canceled");
    }
  }
  const [recipie, setRecipie] = useState([]);
  const getRecipie = async (id) => {
    
      await axiosPrivate.get(`/recipies/recipe/${id}`, {
        id: id,
      }).then((response) => {
        setRecipie(response.data);
        window.scrollTo(0, 1000);
      }
      );
  }


    
    return{recipiename, setRecipiename, getRecipies, recipieslist, setRecipieslist, 
        measurement1, setMeasurement1, measurement2, 
        setMeasurement2, measurement3, setMeasurement3, measurement4, setMeasurement4,
        measurement5, setMeasurement5, measurement6, 
        setMeasurement6, measurement7, setMeasurement7, measurement8, setMeasurement8,
        measurement9, setMeasurement9, measurement10, 
        setMeasurement10, measurement11, setMeasurement11, measurement12, setMeasurement12,
        ingediant1, setIngediant1, ingediant2, 
        setIngediant2, ingediant3, setIngediant3, ingediant4, setIngediant4,
        ingediant5, setIngediant5, ingediant6, 
        setIngediant6, ingediant7, setIngediant7, ingediant8, setIngediant8,
        ingediant9, setIngediant9, ingediant10, 
        setIngediant10, ingediant11, setIngediant11, ingediant12, setIngediant12,
        ingediant1num, setIngediant1num, ingediant2num, 
        setIngediant2num, ingediant3num, setIngediant3num, ingediant4num, setIngediant4num,
        ingediant5num, setIngediant5num, ingediant6num, 
        setIngediant6num, ingediant7num, setIngediant7num, ingediant8num, setIngediant8num,
        ingediant9num, setIngediant9num, ingediant10num, 
        setIngediant10num, ingediant11num, setIngediant11num, ingediant12num, setIngediant12num,
        searchTerm, setSearchTerm,
        notes, setNotes,
        newnotes, setNewnotes,
        newrecipiename, setNewrecipiename,
        times,setTimes,
        resetIngediantNumber,
        updateRecipiename,
        updateNotes,
        addRecipie,
        newIngediantNumber, getBuildprops, getRecipie, recipie, setRecipie,
        confirmDelete, addRecipieprops,   addImglink, buildname, setBuildname, buildpropslist, setBuildpropslist, buildId, setBuildId, buildDescription, setBuildDescription,
        buildimagepath,setBuildimagepath, buildimagepath2, setBuildimagepath2, buildprops, setBuildprops, show, setShow, handleClose , handleShow
    }
}

export default useRecipeutils