import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom"
import useAxiosPrivate from "../hooks/useAxiosPrivate";



const useApizzarecipeUtils = () => {


    const navigate = useNavigate();
    const [accesslocation, setAccessLocation] = useState(0);
    const [accesslocation2, setAccessLocation2] = useState(0);
    
    const axiosPrivate = useAxiosPrivate();
    const [searchTerm, setSearchTerm] = useState('');
    const [recipiename, setRecipiename] = useState("");
  
    const [measurement1, setMeasurement1] = useState("cups");
    const [measurement2, setMeasurement2] = useState("cups");
    const [measurement3, setMeasurement3] = useState("cups");
    const [measurement4, setMeasurement4] = useState("cups");
    const [measurement5, setMeasurement5] = useState("cups");
    const [measurement6, setMeasurement6] = useState("cups");
    const [measurement7, setMeasurement7] = useState("cups");
    const [measurement8, setMeasurement8] = useState("cups");
    const [measurement9, setMeasurement9] = useState("cups");
    const [measurement10, setMeasurement10] = useState("cups");
    const [measurement11, setMeasurement11] = useState("cups");
    const [measurement12, setMeasurement12] = useState("cups");
    const [measurement13, setMeasurement13] = useState("cups");
    const [measurement14, setMeasurement14] = useState("cups");
    const [measurement15, setMeasurement15] = useState("cups");
    const [measurement16, setMeasurement16] = useState("cups");
    const [measurement17, setMeasurement17] = useState("cups");
    const [measurement18, setMeasurement18] = useState("cups");
    const [measurement19, setMeasurement19] = useState("cups");
    const [measurement20, setMeasurement20] = useState("cups");
  
  
  
    const [ingediant1, setIngediant1] = useState("");
    const [ingediant2, setIngediant2] = useState("");
    const [ingediant3, setIngediant3] = useState("");
    const [ingediant4, setIngediant4] = useState("");
    const [ingediant5, setIngediant5] = useState("");
    const [ingediant6, setIngediant6] = useState("");
    const [ingediant7, setIngediant7] = useState("");
    const [ingediant8, setIngediant8] = useState("");
    const [ingediant9, setIngediant9] = useState("");
    const [ingediant10, setIngediant10] = useState("");
    const [ingediant11, setIngediant11] = useState("");
    const [ingediant12, setIngediant12] = useState("");
    const [ingediant13, setIngediant13] = useState("");
    const [ingediant14, setIngediant14] = useState("");
    const [ingediant15, setIngediant15] = useState("");
    const [ingediant16, setIngediant16] = useState("");
    const [ingediant17, setIngediant17] = useState("");
    const [ingediant18, setIngediant18] = useState("");
    const [ingediant19, setIngediant19] = useState("");
    const [ingediant20, setIngediant20] = useState("");
  
    const [ingediant1num, setIngediant1num] = useState(0);
    const [ingediant2num, setIngediant2num] = useState(0);
    const [ingediant3num, setIngediant3num] = useState(0);
    const [ingediant4num, setIngediant4num] = useState(0);
    const [ingediant5num, setIngediant5num] = useState(0);
    const [ingediant6num, setIngediant6num] = useState(0);
    const [ingediant7num, setIngediant7num] = useState(0);
    const [ingediant8num, setIngediant8num] = useState(0);
    const [ingediant9num, setIngediant9num] = useState(0);
    const [ingediant10num, setIngediant10num] = useState(0);
    const [ingediant11num, setIngediant11num] = useState(0);
    const [ingediant12num, setIngediant12num] = useState(0);
    const [ingediant13num, setIngediant13num] = useState(0);
    const [ingediant14num, setIngediant14num] = useState(0);
    const [ingediant15num, setIngediant15num] = useState(0);
    const [ingediant16num, setIngediant16num] = useState(0);
    const [ingediant17num, setIngediant17num] = useState(0);
    const [ingediant18num, setIngediant18num] = useState(0);
    const [ingediant19num, setIngediant19num] = useState(0);
    const [ingediant20num, setIngediant20num] = useState(0);
  
  
    const [notes, setNotes] = useState("");
    const [recipieslist, setRecipieslist] = useState([]);
  
    const [newrecipiename, setNewrecipiename] = useState("");
    
    const [newingediant1num, setNewingediant1num] =useState(0);
    const [newingediant2num, setNewingediant2num] =useState(0);
    const [newingediant3num, setNewingediant3num] =useState(0);
    const [newingediant4num, setNewingediant4num] =useState(0);
    const [newingediant5num, setNewingediant5num] =useState(0);
    const [newingediant6num, setNewingediant6num] =useState(0);
    const [newingediant7num, setNewingediant7num] =useState(0);
    const [newingediant8num, setNewingediant8num] =useState(0);
    const [newingediant9num, setNewingediant9num] =useState(0);
    const [newingediant10num, setNewingediant10num] =useState(0);
    const [newingediant11num, setNewingediant11num] =useState(0);
    const [newingediant12num, setNewingediant12num] =useState(0);
    const [newingediant13num, setNewingediant13num] =useState(0);
    const [newingediant14num, setNewingediant14num] =useState(0);
    const [newingediant15num, setNewingediant15num] =useState(0);
    const [newingediant16num, setNewingediant16num] =useState(0);
    const [newingediant17num, setNewingediant17num] =useState(0);
    const [newingediant18num, setNewingediant18num] =useState(0);
    const [newingediant19num, setNewingediant19num] =useState(0);
    const [newingediant20num, setNewingediant20num] =useState(0);
    const [newnotes, setNewnotes] = useState("");
  
    const [times,setTimes] = useState(0);
  
    const [imglink, setImgLink] = useState("");


    const [buildname, setBuildname] = useState("");
    const [buildpropslist, setBuildpropslist] = useState([]);
    const [buildId, setBuildId] = useState(0);
    const [buildDescription, setBuildDescription] = useState("");
    const [buildimagepath,setBuildimagepath] = useState("");
    const [buildimagepath2, setBuildimagepath2] = useState("");
    const [buildprops, setBuildprops] = useState("")


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    

    const addRecipieprops = async () => {
  
      await axiosPrivate.post("apizza/buildprops", {
        mode: 'cors',
        buildname: buildname,
        buildId: buildId,
        buildDescription: buildDescription,
        buildimagepath: buildimagepath,
        buildimagepath2: buildimagepath2,
          
      }).then(() => {
        setBuildpropslist([
          ...buildpropslist,
          {
            mode: 'cors',
            buildname: buildname,
            buildId: buildId,
            buildDescription: buildDescription,
            buildimagepath: buildimagepath,
            buildimagepath2: buildimagepath2,
             
            
          },
        ]);
        handleClose();
      });
    };
    const addImglink = async () => {
  
      await axiosPrivate.post("apizza/buildprops/imglink", {
        mode: 'cors',
  
        buildId: buildId,
      
        buildimagepath: buildimagepath,
        
          
      }).then(() => {
        setBuildpropslist([
          ...buildpropslist,
          {
            mode: 'cors',
            buildname: buildname,
            buildId: buildId,
            buildDescription: buildDescription,
            buildimagepath: buildimagepath,
            buildimagepath2: buildimagepath2,
             
            
          },
        ]);
        handleClose();
      });
    };
  
    
    const addRecipie = async () => {
        await axiosPrivate.post("/apizza", {
          mode: 'cors',
          recipiename: recipiename,
          measurement1: measurement1,
          measurement2: measurement2,
          measurement3: measurement3,
          measurement4: measurement4,
          measurement5: measurement5,
          measurement6: measurement6,
          measurement7: measurement7,
          measurement8: measurement8,
          measurement9: measurement9,
          measurement10: measurement10,
          measurement11: measurement11,
          measurement12: measurement12,
          measurement13: measurement13,
          measurement14: measurement14,
          measurement15: measurement15,
          measurement16: measurement16,
          measurement17: measurement17,
          measurement18: measurement18,
          measurement19: measurement19,
          measurement20: measurement20,
          ingediant1: ingediant1,
          ingediant2: ingediant2,
          ingediant3: ingediant3,
          ingediant4: ingediant4,
          ingediant5: ingediant5,
          ingediant6: ingediant6,
          ingediant7: ingediant7,
          ingediant8: ingediant8,
          ingediant9: ingediant9,
          ingediant10: ingediant10,
          ingediant11: ingediant11,
          ingediant12: ingediant12,
          ingediant13: ingediant13,
          ingediant14: ingediant14,
          ingediant15: ingediant15,
          ingediant16: ingediant16,
          ingediant17: ingediant17,
          ingediant18: ingediant18,
          ingediant19: ingediant19,
          ingediant20: ingediant20,
          ingediant1num: ingediant1num,
          ingediant2num: ingediant2num,
          ingediant3num: ingediant3num,
          ingediant4num: ingediant4num,
          ingediant5num: ingediant5num,
          ingediant6num: ingediant6num,
          ingediant7num: ingediant7num,
          ingediant8num: ingediant8num,
          ingediant9num: ingediant9num,
          ingediant10num: ingediant10num,
          ingediant11num: ingediant11num,
          ingediant12num: ingediant12num,
          ingediant13num: ingediant13num,
          ingediant14num: ingediant14num,
          ingediant15num: ingediant15num,
          ingediant16num: ingediant16num,
          ingediant17num: ingediant17num,
          ingediant18num: ingediant18num,
          ingediant19num: ingediant19num,
          ingediant20num: ingediant20num,
          notes: notes,
          imglink: imglink
        }).then(() => {
          setRecipieslist([
            ...recipieslist,
            {
              mode: 'cors',
              recipiename: recipiename,
              measurement1: measurement1,
              measurement2: measurement2,
              measurement3: measurement3,
              measurement4: measurement4,
              measurement5: measurement5,
              measurement6: measurement6,
              measurement7: measurement7,
              measurement8: measurement8,
              measurement9: measurement9,
              measurement10: measurement10,
              measurement11: measurement11,
              measurement12: measurement12,
              measurement13: measurement13,
              measurement14: measurement14,
              measurement15: measurement15,
              measurement16: measurement16,
              measurement17: measurement17,
              measurement18: measurement18,
              measurement19: measurement19,
              measurement20: measurement20,
              ingediant1: ingediant1,
              ingediant2: ingediant2,
              ingediant3: ingediant3,
              ingediant4: ingediant4,
              ingediant5: ingediant5,
              ingediant6: ingediant6,
              ingediant7: ingediant7,
              ingediant8: ingediant8,
              ingediant9: ingediant9,
              ingediant10: ingediant10,
              ingediant11: ingediant11,
              ingediant12: ingediant12,
              ingediant13: ingediant13,
              ingediant14: ingediant14,
              ingediant15: ingediant15,
              ingediant16: ingediant16,
              ingediant17: ingediant17,
              ingediant18: ingediant18,
              ingediant19: ingediant19,
              ingediant20: ingediant20,
              ingediant1num: ingediant1num,
              ingediant2num: ingediant2num,
              ingediant3num: ingediant3num,
              ingediant4num: ingediant4num,
              ingediant5num: ingediant5num,
              ingediant6num: ingediant6num,
              ingediant7num: ingediant7num,
              ingediant8num: ingediant8num,
              ingediant9num: ingediant9num,
              ingediant10num: ingediant10num,
              ingediant11num: ingediant11num,
              ingediant12num: ingediant12num,
              ingediant13num: ingediant13num,
              ingediant14num: ingediant14num,
              ingediant15num: ingediant15num,
              ingediant16num: ingediant16num,
              ingediant17num: ingediant17num,
              ingediant18num: ingediant18num,
              ingediant19num: ingediant19num,
              ingediant20num: ingediant20num,
              notes: notes,
              imglink: imglink
            },
          ]);
        });
      };
    
      const resetIngediantNumber = async (id) => {
        await axiosPrivate.get("/apizza", {
          mode: 'cors',
          times: times,
          id: id,
        }).then(
          (response) => {
            setRecipieslist(recipieslist.map((val, key) => {
              return val.id === id ? {
                recipiename: val.recipiename,
                id: val.id,
                measurement1: val.measurement1,
                measurement2: val.measurement2,
                measurement3: val.measurement3,
                measurement4: val.measurement4,
                measurement5: val.measurement5,
                measurement6: val.measurement6,
                measurement7: val.measurement7,
                measurement8: val.measurement8,
                measurement9: val.measurement9,
                measurement10: val.measurement10,
                measurement11: val.measurement11,
                measurement12: val.measurement12,
                measurement13: val.measurement13,
                measurement14: val.measurement14,
                measurement15: val.measurement15,
                measurement16: val.measurement16,
                measurement17: val.measurement17,
                measurement18: val.measurement18,
                measurement19: val.measurement19,
                measurement20: val.measurement20,
                ingediant1: val.ingediant1,
                ingediant2: val.ingediant2,
                ingediant3: val.ingediant3,
                ingediant4: val.ingediant4,
                ingediant5: val.ingediant5,
                ingediant6: val.ingediant6,
                ingediant7: val.ingediant7,
                ingediant8: val.ingediant8,
                ingediant9: val.ingediant9,
                ingediant10: val.ingediant10,
                ingediant11: val.ingediant11,
                ingediant12: val.ingediant12,
                ingediant13: val.ingediant13,
                ingediant14: val.ingediant14,
                ingediant15: val.ingediant15,
                ingediant16: val.ingediant16,
                ingediant17: val.ingediant17,
                ingediant18: val.ingediant18,
                ingediant19: val.ingediant19,
                ingediant20: val.ingediant20,
                ingediant1num: val.ingediant1num/times,
                ingediant2num: val.ingediant2num/times,
                ingediant3num: val.ingediant3num/times,
                ingediant4num: val.ingediant4num/times,
                ingediant5num: val.ingediant5num/times,
                ingediant6num: val.ingediant6num/times,
                ingediant7num: val.ingediant7num/times,
                ingediant8num: val.ingediant8num/times,
                ingediant9num: val.ingediant9num/times,
                ingediant10num: val.ingediant10num/times,
                ingediant11num: val.ingediant11num/times,
                ingediant12num: val.ingediant12num/times,
                ingediant13num: val.ingediant13num/times,
                ingediant14num: val.ingediant14num/times,
                ingediant15num: val.ingediant15num/times,
                ingediant16num: val.ingediant16num/times,
                ingediant17num: val.ingediant17num/times,
                ingediant18num: val.ingediant18num/times,
                ingediant19num: val.ingediant19num/times,
                ingediant20num: val.ingediant20num/times,
                notes: val.notes,
                imglink: val.imglink
              } : val
            }));
          }
        );
      };
    
    
      const newIngediantNumber = async (id) => {
        await axiosPrivate.get("/apizza", {
          mode: 'cors',
          times: times,
          id: id,
        }).then(
          (response) => {
            setRecipieslist(recipieslist.map((val, key) => {
              return val.id === id ? {
                recipiename: val.recipiename,
                id: val.id,
                measurement1: val.measurement1,
                measurement2: val.measurement2,
                measurement3: val.measurement3,
                measurement4: val.measurement4,
                measurement5: val.measurement5,
                measurement6: val.measurement6,
                measurement7: val.measurement7,
                measurement8: val.measurement8,
                measurement9: val.measurement9,
                measurement10: val.measurement10,
                measurement11: val.measurement11,
                measurement12: val.measurement12,
                measurement13: val.measurement13,
                measurement14: val.measurement14,
                measurement15: val.measurement15,
                measurement16: val.measurement16,
                measurement17: val.measurement17,
                measurement18: val.measurement18,
                measurement19: val.measurement19,
                measurement20: val.measurement20,
                ingediant1: val.ingediant1,
                ingediant2: val.ingediant2,
                ingediant3: val.ingediant3,
                ingediant4: val.ingediant4,
                ingediant5: val.ingediant5,
                ingediant6: val.ingediant6,
                ingediant7: val.ingediant7,
                ingediant8: val.ingediant8,
                ingediant9: val.ingediant9,
                ingediant10: val.ingediant10,
                ingediant11: val.ingediant11,
                ingediant12: val.ingediant12,
                ingediant13: val.ingediant13,
                ingediant14: val.ingediant14,
                ingediant15: val.ingediant15,
                ingediant16: val.ingediant16,
                ingediant17: val.ingediant17,
                ingediant18: val.ingediant18,
                ingediant19: val.ingediant19,
                ingediant20: val.ingediant20,
                ingediant1num: val.ingediant1num*times,
                ingediant2num: val.ingediant2num*times,
                ingediant3num: val.ingediant3num*times,
                ingediant4num: val.ingediant4num*times,
                ingediant5num: val.ingediant5num*times,
                ingediant6num: val.ingediant6num*times,
                ingediant7num: val.ingediant7num*times,
                ingediant8num: val.ingediant8num*times,
                ingediant9num: val.ingediant9num*times,
                ingediant10num: val.ingediant10num*times,
                ingediant11num: val.ingediant11num*times,
                ingediant12num: val.ingediant12num*times,
                ingediant13num: val.ingediant13num*times,
                ingediant14num: val.ingediant14num*times,
                ingediant15num: val.ingediant15num*times,
                ingediant16num: val.ingediant16num*times,
                ingediant17num: val.ingediant17num*times,
                ingediant18num: val.ingediant18num*times,
                ingediant19num: val.ingediant19num*times,
                ingediant20num: val.ingediant20num*times,
                notes: val.notes,
                imglink: val.imglink
              } : val
            }));
          }
        );
      };
    
      const getRecipies = async  () => {
        locationlock();
       await axiosPrivate.get("/apizza").then((response) => {
          setRecipieslist(response.data);
          window.scrollTo(0, 1000);
        });
      };
      
      const [recipie, setRecipie] = useState([]);
      const getRecipie = async (id) => {
        locationlock();
          await axiosPrivate.get(`/apizza/recipe/${id}`, {
            id: id,
          }).then((response) => {
            setRecipie(response.data);
            window.scrollTo(0, 1000);
          }
          );
      }
   
      
      const getBuildprops = useCallback( async (buildimagepath) => {
        
        // locationlock();
        
   await axiosPrivate.get(`/apizza/buildprops/${buildimagepath}`,  {
             buildimagepath: buildimagepath,
           }).then((response) => {
            console.log(response.data);

             setBuildprops(response.data);
             setBuildname(response.data[0].buildname);
             setBuildId(response.data[0].buildId);
             setBuildDescription(response.data[0].buildDescription);
             setBuildimagepath(response.data[0].buildimagepath);
             setBuildimagepath2(response.data[0].buildimagepath2);
             window.scrollTo(0, 1000);
           }
           )
       },[axiosPrivate])
       
    
      const updateRecipiename = async (id) => {
        await axiosPrivate.put("apizza/recipename", {
          mode: 'cors',
          recipiename: newrecipiename,
          id: id,
        }).then(
          (response) => {
            setRecipieslist(recipieslist.map((val) => {
              return val.id === id ? {
                recipiename: newrecipiename,
                id: val.id,
                measurement1: val.measurement1,
                measurement2: val.measurement2,
                measurement3: val.measurement3,
                measurement4: val.measurement4,
                measurement5: val.measurement5,
                measurement6: val.measurement6,
                measurement7: val.measurement7,
                measurement8: val.measurement8,
                measurement9: val.measurement9,
                measurement10: val.measurement10,
                measurement11: val.measurement11,
                measurement12: val.measurement12,
                measurement13: val.measurement13,
                measurement14: val.measurement14,
                measurement15: val.measurement15,
                measurement16: val.measurement16,
                measurement17: val.measurement17,
                measurement18: val.measurement18,
                measurement19: val.measurement19,
                measurement20: val.measurement20,
                ingediant1: val.ingediant1,
                ingediant2: val.ingediant2,
                ingediant3: val.ingediant3,
                ingediant4: val.ingediant4,
                ingediant5: val.ingediant5,
                ingediant6: val.ingediant6,
                ingediant7: val.ingediant7,
                ingediant8: val.ingediant8,
                ingediant9: val.ingediant9,
                ingediant10: val.ingediant10,
                ingediant11: val.ingediant11,
                ingediant12: val.ingediant12,
                ingediant13: val.ingediant13,
                ingediant14: val.ingediant14,
                ingediant15: val.ingediant15,
                ingediant16: val.ingediant16,
                ingediant17: val.ingediant17,
                ingediant18: val.ingediant18,
                ingediant19: val.ingediant19,
                ingediant20: val.ingediant20,
                ingediant1num: val.ingediant1num,
                ingediant2num: val.ingediant2num,
                ingediant3num: val.ingediant3num,
                ingediant4num: val.ingediant4num,
                ingediant5num: val.ingediant5num,
                ingediant6num: val.ingediant6num,
                ingediant7num: val.ingediant7num,
                ingediant8num: val.ingediant8num,
                ingediant9num: val.ingediant9num,
                ingediant10num: val.ingediant10num,
                ingediant11num: val.ingediant11num,
                ingediant12num: val.ingediant12num,
                ingediant13num: val.ingediant13num,
                ingediant14num: val.ingediant14num,
                ingediant15num: val.ingediant15num,
                ingediant16num: val.ingediant16num,
                ingediant17num: val.ingediant17num,
                ingediant18num: val.ingediant18num,
                ingediant19num: val.ingediant19num,
                ingediant20num: val.ingediant20num,
                notes: val.notes,
                imglink: val.imglink
              } : val
            }));
          }
        );
      };
    
      const updateIngediant1num = async (id) => {
        await axiosPrivate.put("apizza/ingrediant1num", {
          mode: 'cors',
          ingediant1num: newingediant1num,
          id: id,
        }).then(
          (response) => {
            setRecipieslist(recipieslist.map((val) => {
              return val.id === id ? {
                recipiename: val.recipiename,
                id: val.id,
                measurement1: val.measurement1,
                measurement2: val.measurement2,
                measurement3: val.measurement3,
                measurement4: val.measurement4,
                measurement5: val.measurement5,
                measurement6: val.measurement6,
                measurement7: val.measurement7,
                measurement8: val.measurement8,
                measurement9: val.measurement9,
                measurement10: val.measurement10,
                measurement11: val.measurement11,
                measurement12: val.measurement12,
                measurement13: val.measurement13,
                measurement14: val.measurement14,
                measurement15: val.measurement15,
                measurement16: val.measurement16,
                measurement17: val.measurement17,
                measurement18: val.measurement18,
                measurement19: val.measurement19,
                measurement20: val.measurement20,
                ingediant1: val.ingediant1,
                ingediant2: val.ingediant2,
                ingediant3: val.ingediant3,
                ingediant4: val.ingediant4,
                ingediant5: val.ingediant5,
                ingediant6: val.ingediant6,
                ingediant7: val.ingediant7,
                ingediant8: val.ingediant8,
                ingediant9: val.ingediant9,
                ingediant10: val.ingediant10,
                ingediant11: val.ingediant11,
                ingediant12: val.ingediant12,
                ingediant13: val.ingediant13,
                ingediant14: val.ingediant14,
                ingediant15: val.ingediant15,
                ingediant16: val.ingediant16,
                ingediant17: val.ingediant17,
                ingediant18: val.ingediant18,
                ingediant19: val.ingediant19,
                ingediant20: val.ingediant20,
                ingediant1num: newingediant1num,
                ingediant2num: val.ingediant2num,
                ingediant3num: val.ingediant3num,
                ingediant4num: val.ingediant4num,
                ingediant5num: val.ingediant5num,
                ingediant6num: val.ingediant6num,
                ingediant7num: val.ingediant7num,
                ingediant8num: val.ingediant8num,
                ingediant9num: val.ingediant9num,
                ingediant10num: val.ingediant10num,
                ingediant11num: val.ingediant11num,
                ingediant12num: val.ingediant12num,
                ingediant13num: val.ingediant13num,
                ingediant14num: val.ingediant14num,
                ingediant15num: val.ingediant15num,
                ingediant16num: val.ingediant16num,
                ingediant17num: val.ingediant17num,
                ingediant18num: val.ingediant18num,
                ingediant19num: val.ingediant19num,
                ingediant20num: val.ingediant20num,
                notes: val.notes,
                imglink: val.imglink
              } : val
            }));
          }
        );
      };
    
      const updateIngediant2num = async (id) => {
        await axiosPrivate.put("apizza/ingrediant2num", {
          mode: 'cors',
          ingediant2num: newingediant2num,
          id: id,
        }).then(
          (response) => {
            setRecipieslist(recipieslist.map((val) => {
              return val.id === id ? {
                recipiename: val.recipiename,
                id: val.id,
                measurement1: val.measurement1,
                measurement2: val.measurement2,
                measurement3: val.measurement3,
                measurement4: val.measurement4,
                measurement5: val.measurement5,
                measurement6: val.measurement6,
                measurement7: val.measurement7,
                measurement8: val.measurement8,
                measurement9: val.measurement9,
                measurement10: val.measurement10,
                measurement11: val.measurement11,
                measurement12: val.measurement12,
                measurement13: val.measurement13,
                measurement14: val.measurement14,
                measurement15: val.measurement15,
                measurement16: val.measurement16,
                measurement17: val.measurement17,
                measurement18: val.measurement18,
                measurement19: val.measurement19,
                measurement20: val.measurement20,
                ingediant1: val.ingediant1,
                ingediant2: val.ingediant2,
                ingediant3: val.ingediant3,
                ingediant4: val.ingediant4,
                ingediant5: val.ingediant5,
                ingediant6: val.ingediant6,
                ingediant7: val.ingediant7,
                ingediant8: val.ingediant8,
                ingediant9: val.ingediant9,
                ingediant10: val.ingediant10,
                ingediant11: val.ingediant11,
                ingediant12: val.ingediant12,
                ingediant13: val.ingediant13,
                ingediant14: val.ingediant14,
                ingediant15: val.ingediant15,
                ingediant16: val.ingediant16,
                ingediant17: val.ingediant17,
                ingediant18: val.ingediant18,
                ingediant19: val.ingediant19,
                ingediant20: val.ingediant20,
                ingediant1num: val.ingediant1num,
                ingediant2num: newingediant2num,
                ingediant3num: val.ingediant3num,
                ingediant4num: val.ingediant4num,
                ingediant5num: val.ingediant5num,
                ingediant6num: val.ingediant6num,
                ingediant7num: val.ingediant7num,
                ingediant8num: val.ingediant8num,
                ingediant9num: val.ingediant9num,
                ingediant10num: val.ingediant10num,
                ingediant11num: val.ingediant11num,
                ingediant12num: val.ingediant12num,
                ingediant13num: val.ingediant13num,
                ingediant14num: val.ingediant14num,
                ingediant15num: val.ingediant15num,
                ingediant16num: val.ingediant16num,
                ingediant17num: val.ingediant17num,
                ingediant18num: val.ingediant18num,
                ingediant19num: val.ingediant19num,
                ingediant20num: val.ingediant20num,
                notes: val.notes,
                imglink: val.imglink
              } : val
            }));
          }
        );
      };
      const updateIngediant3num = async (id) => {
        await axiosPrivate.put("apizza/ingrediant3num", {
          mode: 'cors',
          ingediant3num: newingediant3num,
          id: id,
        }).then(
          (response) => {
            setRecipieslist(recipieslist.map((val) => {
              return val.id === id ? {
                recipiename: val.recipiename,
                id: val.id,
                measurement1: val.measurement1,
                measurement2: val.measurement2,
                measurement3: val.measurement3,
                measurement4: val.measurement4,
                measurement5: val.measurement5,
                measurement6: val.measurement6,
                measurement7: val.measurement7,
                measurement8: val.measurement8,
                measurement9: val.measurement9,
                measurement10: val.measurement10,
                measurement11: val.measurement11,
                measurement12: val.measurement12,
                measurement13: val.measurement13,
                measurement14: val.measurement14,
                measurement15: val.measurement15,
                measurement16: val.measurement16,
                measurement17: val.measurement17,
                measurement18: val.measurement18,
                measurement19: val.measurement19,
                measurement20: val.measurement20,
                ingediant1: val.ingediant1,
                ingediant2: val.ingediant2,
                ingediant3: val.ingediant3,
                ingediant4: val.ingediant4,
                ingediant5: val.ingediant5,
                ingediant6: val.ingediant6,
                ingediant7: val.ingediant7,
                ingediant8: val.ingediant8,
                ingediant9: val.ingediant9,
                ingediant10: val.ingediant10,
                ingediant11: val.ingediant11,
                ingediant12: val.ingediant12,
                ingediant13: val.ingediant13,
                ingediant14: val.ingediant14,
                ingediant15: val.ingediant15,
                ingediant16: val.ingediant16,
                ingediant17: val.ingediant17,
                ingediant18: val.ingediant18,
                ingediant19: val.ingediant19,
                ingediant20: val.ingediant20,
                ingediant1num: val.ingediant1num,
                ingediant2num: val.ingediant2num,
                ingediant3num: newingediant3num,
                ingediant4num: val.ingediant4num,
                ingediant5num: val.ingediant5num,
                ingediant6num: val.ingediant6num,
                ingediant7num: val.ingediant7num,
                ingediant8num: val.ingediant8num,
                ingediant9num: val.ingediant9num,
                ingediant10num: val.ingediant10num,
                ingediant11num: val.ingediant11num,
                ingediant12num: val.ingediant12num,
                ingediant13num: val.ingediant13num,
                ingediant14num: val.ingediant14num,
                ingediant15num: val.ingediant15num,
                ingediant16num: val.ingediant16num,
                ingediant17num: val.ingediant17num,
                ingediant18num: val.ingediant18num,
                ingediant19num: val.ingediant19num,
                ingediant20num: val.ingediant20num,
                notes: val.notes,
                imglink: val.imglink
              } : val
            }));
          }
        );
      };
      const updateIngediant4num = async (id) => {
        await axiosPrivate.put("apizza/ingrediant4num", {
          mode: 'cors',
          ingediant4num: newingediant4num,
          id: id,
        }).then(
          (response) => {
            setRecipieslist(recipieslist.map((val) => {
              return val.id === id ? {
                recipiename: val.recipiename,
                id: val.id,
                measurement1: val.measurement1,
                measurement2: val.measurement2,
                measurement3: val.measurement3,
                measurement4: val.measurement4,
                measurement5: val.measurement5,
                measurement6: val.measurement6,
                measurement7: val.measurement7,
                measurement8: val.measurement8,
                measurement9: val.measurement9,
                measurement10: val.measurement10,
                measurement11: val.measurement11,
                measurement12: val.measurement12,
                measurement13: val.measurement13,
                measurement14: val.measurement14,
                measurement15: val.measurement15,
                measurement16: val.measurement16,
                measurement17: val.measurement17,
                measurement18: val.measurement18,
                measurement19: val.measurement19,
                measurement20: val.measurement20,
                ingediant1: val.ingediant1,
                ingediant2: val.ingediant2,
                ingediant3: val.ingediant3,
                ingediant4: val.ingediant4,
                ingediant5: val.ingediant5,
                ingediant6: val.ingediant6,
                ingediant7: val.ingediant7,
                ingediant8: val.ingediant8,
                ingediant9: val.ingediant9,
                ingediant10: val.ingediant10,
                ingediant11: val.ingediant11,
                ingediant12: val.ingediant12,
                ingediant13: val.ingediant13,
                ingediant14: val.ingediant14,
                ingediant15: val.ingediant15,
                ingediant16: val.ingediant16,
                ingediant17: val.ingediant17,
                ingediant18: val.ingediant18,
                ingediant19: val.ingediant19,
                ingediant20: val.ingediant20,
                ingediant1num: val.ingediant1num,
                ingediant2num: val.ingediant2num,
                ingediant3num: val.ingediant3num,
                ingediant4num: newingediant4num,
                ingediant5num: val.ingediant5num,
                ingediant6num: val.ingediant6num,
                ingediant7num: val.ingediant7num,
                ingediant8num: val.ingediant8num,
                ingediant9num: val.ingediant9num,
                ingediant10num: val.ingediant10num,
                ingediant11num: val.ingediant11num,
                ingediant12num: val.ingediant12num,
                ingediant13num: val.ingediant13num,
                ingediant14num: val.ingediant14num,
                ingediant15num: val.ingediant15num,
                ingediant16num: val.ingediant16num,
                ingediant17num: val.ingediant17num,
                ingediant18num: val.ingediant18num,
                ingediant19num: val.ingediant19num,
                ingediant20num: val.ingediant20num,
                notes: val.notes,
                imglink: val.imglink
              } : val
            }));
          }
        );
      };
      const updateIngediant5num = async (id) => {
        await axiosPrivate.put("apizza/ingrediant5num", {
          mode: 'cors',
          ingediant5num: newingediant5num,
          id: id,
        }).then(
          (response) => {
            setRecipieslist(recipieslist.map((val) => {
              return val.id === id ? {
                recipiename: val.recipiename,
                id: val.id,
                measurement1: val.measurement1,
                measurement2: val.measurement2,
                measurement3: val.measurement3,
                measurement4: val.measurement4,
                measurement5: val.measurement5,
                measurement6: val.measurement6,
                measurement7: val.measurement7,
                measurement8: val.measurement8,
                measurement9: val.measurement9,
                measurement10: val.measurement10,
                measurement11: val.measurement11,
                measurement12: val.measurement12,
                measurement13: val.measurement13,
                measurement14: val.measurement14,
                measurement15: val.measurement15,
                measurement16: val.measurement16,
                measurement17: val.measurement17,
                measurement18: val.measurement18,
                measurement19: val.measurement19,
                measurement20: val.measurement20,
                ingediant1: val.ingediant1,
                ingediant2: val.ingediant2,
                ingediant3: val.ingediant3,
                ingediant4: val.ingediant4,
                ingediant5: val.ingediant5,
                ingediant6: val.ingediant6,
                ingediant7: val.ingediant7,
                ingediant8: val.ingediant8,
                ingediant9: val.ingediant9,
                ingediant10: val.ingediant10,
                ingediant11: val.ingediant11,
                ingediant12: val.ingediant12,
                ingediant13: val.ingediant13,
                ingediant14: val.ingediant14,
                ingediant15: val.ingediant15,
                ingediant16: val.ingediant16,
                ingediant17: val.ingediant17,
                ingediant18: val.ingediant18,
                ingediant19: val.ingediant19,
                ingediant20: val.ingediant20,
                ingediant1num: val.ingediant1num,
                ingediant2num: val.ingediant2num,
                ingediant3num: val.ingediant3num,
                ingediant4num: val.ingediant4num,
                ingediant5num: newingediant5num,
                ingediant6num: val.ingediant6num,
                ingediant7num: val.ingediant7num,
                ingediant8num: val.ingediant8num,
                ingediant9num: val.ingediant9num,
                ingediant10num: val.ingediant10num,
                ingediant11num: val.ingediant11num,
                ingediant12num: val.ingediant12num,
                ingediant13num: val.ingediant13num,
                ingediant14num: val.ingediant14num,
                ingediant15num: val.ingediant15num,
                ingediant16num: val.ingediant16num,
                ingediant17num: val.ingediant17num,
                ingediant18num: val.ingediant18num,
                ingediant19num: val.ingediant19num,
                ingediant20num: val.ingediant20num,
                notes: val.notes,
                imglink: val.imglink
              } : val
            }));
          }
        );
      };
      const updateIngediant6num = async (id) => {
        await axiosPrivate.put("apizza/ingrediant6num", {
          mode: 'cors',
          ingediant6num: newingediant6num,
          id: id,
        }).then(
          (response) => {
            setRecipieslist(recipieslist.map((val) => {
              return val.id === id ? {
                recipiename: val.recipiename,
                id: val.id,
                measurement1: val.measurement1,
                measurement2: val.measurement2,
                measurement3: val.measurement3,
                measurement4: val.measurement4,
                measurement5: val.measurement5,
                measurement6: val.measurement6,
                measurement7: val.measurement7,
                measurement8: val.measurement8,
                measurement9: val.measurement9,
                measurement10: val.measurement10,
                measurement11: val.measurement11,
                measurement12: val.measurement12,
                measurement13: val.measurement13,
                measurement14: val.measurement14,
                measurement15: val.measurement15,
                measurement16: val.measurement16,
                measurement17: val.measurement17,
                measurement18: val.measurement18,
                measurement19: val.measurement19,
                measurement20: val.measurement20,
                ingediant1: val.ingediant1,
                ingediant2: val.ingediant2,
                ingediant3: val.ingediant3,
                ingediant4: val.ingediant4,
                ingediant5: val.ingediant5,
                ingediant6: val.ingediant6,
                ingediant7: val.ingediant7,
                ingediant8: val.ingediant8,
                ingediant9: val.ingediant9,
                ingediant10: val.ingediant10,
                ingediant11: val.ingediant11,
                ingediant12: val.ingediant12,
                ingediant13: val.ingediant13,
                ingediant14: val.ingediant14,
                ingediant15: val.ingediant15,
                ingediant16: val.ingediant16,
                ingediant17: val.ingediant17,
                ingediant18: val.ingediant18,
                ingediant19: val.ingediant19,
                ingediant20: val.ingediant20,
                ingediant1num: val.ingediant1num,
                ingediant2num: val.ingediant2num,
                ingediant3num: val.ingediant3num,
                ingediant4num: val.ingediant4num,
                ingediant5num: val.ingediant5num,
                ingediant6num: newingediant6num,
                ingediant7num: val.ingediant7num,
                ingediant8num: val.ingediant8num,
                ingediant9num: val.ingediant9num,
                ingediant10num: val.ingediant10num,
                ingediant11num: val.ingediant11num,
                ingediant12num: val.ingediant12num,
                ingediant13num: val.ingediant13num,
                ingediant14num: val.ingediant14num,
                ingediant15num: val.ingediant15num,
                ingediant16num: val.ingediant16num,
                ingediant17num: val.ingediant17num,
                ingediant18num: val.ingediant18num,
                ingediant19num: val.ingediant19num,
                ingediant20num: val.ingediant20num,
                notes: val.notes,
                imglink: val.imglink
              } : val
            }));
          }
        );
      };
      const updateIngediant7num = async (id) => {
        await axiosPrivate.put("apizza/ingrediant7num", {
          mode: 'cors',
          ingediant7num: newingediant7num,
          id: id,
        }).then(
          (response) => {
            setRecipieslist(recipieslist.map((val) => {
              return val.id === id ? {
                recipiename: val.recipiename,
                id: val.id,
                measurement1: val.measurement1,
                measurement2: val.measurement2,
                measurement3: val.measurement3,
                measurement4: val.measurement4,
                measurement5: val.measurement5,
                measurement6: val.measurement6,
                measurement7: val.measurement7,
                measurement8: val.measurement8,
                measurement9: val.measurement9,
                measurement10: val.measurement10,
                measurement11: val.measurement11,
                measurement12: val.measurement12,
                measurement13: val.measurement13,
                measurement14: val.measurement14,
                measurement15: val.measurement15,
                measurement16: val.measurement16,
                measurement17: val.measurement17,
                measurement18: val.measurement18,
                measurement19: val.measurement19,
                measurement20: val.measurement20,
                ingediant1: val.ingediant1,
                ingediant2: val.ingediant2,
                ingediant3: val.ingediant3,
                ingediant4: val.ingediant4,
                ingediant5: val.ingediant5,
                ingediant6: val.ingediant6,
                ingediant7: val.ingediant7,
                ingediant8: val.ingediant8,
                ingediant9: val.ingediant9,
                ingediant10: val.ingediant10,
                ingediant11: val.ingediant11,
                ingediant12: val.ingediant12,
                ingediant13: val.ingediant13,
                ingediant14: val.ingediant14,
                ingediant15: val.ingediant15,
                ingediant16: val.ingediant16,
                ingediant17: val.ingediant17,
                ingediant18: val.ingediant18,
                ingediant19: val.ingediant19,
                ingediant20: val.ingediant20,
                ingediant1num: val.ingediant1num,
                ingediant2num: val.ingediant2num,
                ingediant3num: val.ingediant3num,
                ingediant4num: val.ingediant4num,
                ingediant5num: val.ingediant5num,
                ingediant6num: val.ingediant6num,
                ingediant7num: newingediant7num,
                ingediant8num: val.ingediant8num,
                ingediant9num: val.ingediant9num,
                ingediant10num: val.ingediant10num,
                ingediant11num: val.ingediant11num,
                ingediant12num: val.ingediant12num,
                ingediant13num: val.ingediant13num,
                ingediant14num: val.ingediant14num,
                ingediant15num: val.ingediant15num,
                ingediant16num: val.ingediant16num,
                ingediant17num: val.ingediant17num,
                ingediant18num: val.ingediant18num,
                ingediant19num: val.ingediant19num,
                ingediant20num: val.ingediant20num,
                notes: val.notes,
                imglink: val.imglink
              } : val
            }));
          }
        );
      };
      const updateIngediant8num = async (id) => {
        await axiosPrivate.put("apizza/ingrediant8num", {
          mode: 'cors',
          ingediant8num: newingediant8num,
          id: id,
        }).then(
          (response) => {
            setRecipieslist(recipieslist.map((val) => {
              return val.id === id ? {
                recipiename: val.recipiename,
                id: val.id,
                measurement1: val.measurement1,
                measurement2: val.measurement2,
                measurement3: val.measurement3,
                measurement4: val.measurement4,
                measurement5: val.measurement5,
                measurement6: val.measurement6,
                measurement7: val.measurement7,
                measurement8: val.measurement8,
                measurement9: val.measurement9,
                measurement10: val.measurement10,
                measurement11: val.measurement11,
                measurement12: val.measurement12,
                measurement13: val.measurement13,
                measurement14: val.measurement14,
                measurement15: val.measurement15,
                measurement16: val.measurement16,
                measurement17: val.measurement17,
                measurement18: val.measurement18,
                measurement19: val.measurement19,
                measurement20: val.measurement20,
                ingediant1: val.ingediant1,
                ingediant2: val.ingediant2,
                ingediant3: val.ingediant3,
                ingediant4: val.ingediant4,
                ingediant5: val.ingediant5,
                ingediant6: val.ingediant6,
                ingediant7: val.ingediant7,
                ingediant8: val.ingediant8,
                ingediant9: val.ingediant9,
                ingediant10: val.ingediant10,
                ingediant11: val.ingediant11,
                ingediant12: val.ingediant12,
                ingediant13: val.ingediant13,
                ingediant14: val.ingediant14,
                ingediant15: val.ingediant15,
                ingediant16: val.ingediant16,
                ingediant17: val.ingediant17,
                ingediant18: val.ingediant18,
                ingediant19: val.ingediant19,
                ingediant20: val.ingediant20,
                ingediant1num: val.ingediant1num,
                ingediant2num: val.ingediant2num,
                ingediant3num: val.ingediant3num,
                ingediant4num: val.ingediant4num,
                ingediant5num: val.ingediant5num,
                ingediant6num: val.ingediant6num,
                ingediant7num: val.ingediant7num,
                ingediant8num: newingediant8num,
                ingediant9num: val.ingediant9num,
                ingediant10num: val.ingediant10num,
                ingediant11num: val.ingediant11num,
                ingediant12num: val.ingediant12num,
                ingediant13num: val.ingediant13num,
                ingediant14num: val.ingediant14num,
                ingediant15num: val.ingediant15num,
                ingediant16num: val.ingediant16num,
                ingediant17num: val.ingediant17num,
                ingediant18num: val.ingediant18num,
                ingediant19num: val.ingediant19num,
                ingediant20num: val.ingediant20num,
                notes: val.notes,
                imglink: val.imglink
              } : val
            }));
          }
        );
      };
      const updateIngediant9num = async (id) => {
        await axiosPrivate.put("apizza/ingrediant9num", {
          mode: 'cors',
          ingediant9num: newingediant9num,
          id: id,
        }).then(
          (response) => {
            setRecipieslist(recipieslist.map((val) => {
              return val.id === id ? {
                recipiename: val.recipiename,
                id: val.id,
                measurement1: val.measurement1,
                measurement2: val.measurement2,
                measurement3: val.measurement3,
                measurement4: val.measurement4,
                measurement5: val.measurement5,
                measurement6: val.measurement6,
                measurement7: val.measurement7,
                measurement8: val.measurement8,
                measurement9: val.measurement9,
                measurement10: val.measurement10,
                measurement11: val.measurement11,
                measurement12: val.measurement12,
                measurement13: val.measurement13,
                measurement14: val.measurement14,
                measurement15: val.measurement15,
                measurement16: val.measurement16,
                measurement17: val.measurement17,
                measurement18: val.measurement18,
                measurement19: val.measurement19,
                measurement20: val.measurement20,
                ingediant1: val.ingediant1,
                ingediant2: val.ingediant2,
                ingediant3: val.ingediant3,
                ingediant4: val.ingediant4,
                ingediant5: val.ingediant5,
                ingediant6: val.ingediant6,
                ingediant7: val.ingediant7,
                ingediant8: val.ingediant8,
                ingediant9: val.ingediant9,
                ingediant10: val.ingediant10,
                ingediant11: val.ingediant11,
                ingediant12: val.ingediant12,
                ingediant13: val.ingediant13,
                ingediant14: val.ingediant14,
                ingediant15: val.ingediant15,
                ingediant16: val.ingediant16,
                ingediant17: val.ingediant17,
                ingediant18: val.ingediant18,
                ingediant19: val.ingediant19,
                ingediant20: val.ingediant20,
                ingediant1num: val.ingediant1num,
                ingediant2num: val.ingediant2num,
                ingediant3num: val.ingediant3num,
                ingediant4num: val.ingediant4num,
                ingediant5num: val.ingediant5num,
                ingediant6num: val.ingediant6num,
                ingediant7num: val.ingediant7num,
                ingediant8num: val.ingediant8num,
                ingediant9num: newingediant9num,
                ingediant10num: val.ingediant10num,
                ingediant11num: val.ingediant11num,
                ingediant12num: val.ingediant12num,
                ingediant13num: val.ingediant13num,
                ingediant14num: val.ingediant14num,
                ingediant15num: val.ingediant15num,
                ingediant16num: val.ingediant16num,
                ingediant17num: val.ingediant17num,
                ingediant18num: val.ingediant18num,
                ingediant19num: val.ingediant19num,
                ingediant20num: val.ingediant20num,
                notes: val.notes,
                imglink: val.imglink
              } : val
            }));
          }
        );
      };
      const updateIngediant10num = async (id) => {
        await axiosPrivate.put("apizza/ingrediant10num", {
          mode: 'cors',
          ingediant10num: newingediant10num,
          id: id,
        }).then(
          (response) => {
            setRecipieslist(recipieslist.map((val) => {
              return val.id === id ? {
                recipiename: val.recipiename,
                id: val.id,
                measurement1: val.measurement1,
                measurement2: val.measurement2,
                measurement3: val.measurement3,
                measurement4: val.measurement4,
                measurement5: val.measurement5,
                measurement6: val.measurement6,
                measurement7: val.measurement7,
                measurement8: val.measurement8,
                measurement9: val.measurement9,
                measurement10: val.measurement10,
                measurement11: val.measurement11,
                measurement12: val.measurement12,
                measurement13: val.measurement13,
                measurement14: val.measurement14,
                measurement15: val.measurement15,
                measurement16: val.measurement16,
                measurement17: val.measurement17,
                measurement18: val.measurement18,
                measurement19: val.measurement19,
                measurement20: val.measurement20,
                ingediant1: val.ingediant1,
                ingediant2: val.ingediant2,
                ingediant3: val.ingediant3,
                ingediant4: val.ingediant4,
                ingediant5: val.ingediant5,
                ingediant6: val.ingediant6,
                ingediant7: val.ingediant7,
                ingediant8: val.ingediant8,
                ingediant9: val.ingediant9,
                ingediant10: val.ingediant10,
                ingediant11: val.ingediant11,
                ingediant12: val.ingediant12,
                ingediant13: val.ingediant13,
                ingediant14: val.ingediant14,
                ingediant15: val.ingediant15,
                ingediant16: val.ingediant16,
                ingediant17: val.ingediant17,
                ingediant18: val.ingediant18,
                ingediant19: val.ingediant19,
                ingediant20: val.ingediant20,
                ingediant1num: newingediant1num,
                ingediant2num: val.ingediant2num,
                ingediant3num: val.ingediant3num,
                ingediant4num: val.ingediant4num,
                ingediant5num: val.ingediant5num,
                ingediant6num: val.ingediant6num,
                ingediant7num: val.ingediant7num,
                ingediant8num: val.ingediant8num,
                ingediant9num: val.ingediant9num,
                ingediant10num: newingediant10num,
                ingediant11num: val.ingediant11num,
                ingediant12num: val.ingediant12num,
                ingediant13num: val.ingediant13num,
                ingediant14num: val.ingediant14num,
                ingediant15num: val.ingediant15num,
                ingediant16num: val.ingediant16num,
                ingediant17num: val.ingediant17num,
                ingediant18num: val.ingediant18num,
                ingediant19num: val.ingediant19num,
                ingediant20num: val.ingediant20num,
                notes: val.notes,
                imglink: val.imglink
              } : val
            }));
          }
        );
      };
      const updateIngediant11num = async (id) => {
        await axiosPrivate.put("apizza/ingrediant11num", {
          mode: 'cors',
          ingediant11num: newingediant11num,
          id: id,
        }).then(
          (response) => {
            setRecipieslist(recipieslist.map((val) => {
              return val.id === id ? {
                recipiename: val.recipiename,
                id: val.id,
                measurement1: val.measurement1,
                measurement2: val.measurement2,
                measurement3: val.measurement3,
                measurement4: val.measurement4,
                measurement5: val.measurement5,
                measurement6: val.measurement6,
                measurement7: val.measurement7,
                measurement8: val.measurement8,
                measurement9: val.measurement9,
                measurement10: val.measurement10,
                measurement11: val.measurement11,
                measurement12: val.measurement12,
                measurement13: val.measurement13,
                measurement14: val.measurement14,
                measurement15: val.measurement15,
                measurement16: val.measurement16,
                measurement17: val.measurement17,
                measurement18: val.measurement18,
                measurement19: val.measurement19,
                measurement20: val.measurement20,
                ingediant1: val.ingediant1,
                ingediant2: val.ingediant2,
                ingediant3: val.ingediant3,
                ingediant4: val.ingediant4,
                ingediant5: val.ingediant5,
                ingediant6: val.ingediant6,
                ingediant7: val.ingediant7,
                ingediant8: val.ingediant8,
                ingediant9: val.ingediant9,
                ingediant10: val.ingediant10,
                ingediant11: val.ingediant11,
                ingediant12: val.ingediant12,
                ingediant13: val.ingediant13,
                ingediant14: val.ingediant14,
                ingediant15: val.ingediant15,
                ingediant16: val.ingediant16,
                ingediant17: val.ingediant17,
                ingediant18: val.ingediant18,
                ingediant19: val.ingediant19,
                ingediant20: val.ingediant20,
                ingediant1num: val.ingediant1num,
                ingediant2num: val.ingediant2num,
                ingediant3num: val.ingediant3num,
                ingediant4num: val.ingediant4num,
                ingediant5num: val.ingediant5num,
                ingediant6num: val.ingediant6num,
                ingediant7num: val.ingediant7num,
                ingediant8num: val.ingediant8num,
                ingediant9num: val.ingediant9num,
                ingediant10num: val.ingediant10num,
                ingediant11num: newingediant11num,
                ingediant12num: val.ingediant12num,
                ingediant13num: val.ingediant13num,
                ingediant14num: val.ingediant14num,
                ingediant15num: val.ingediant15num,
                ingediant16num: val.ingediant16num,
                ingediant17num: val.ingediant17num,
                ingediant18num: val.ingediant18num,
                ingediant19num: val.ingediant19num,
                ingediant20num: val.ingediant20num,
                notes: val.notes,
                imglink: val.imglink
              } : val
            }));
          }
        );
      };
      const updateIngediant12num = async (id) => {
        await axiosPrivate.put("apizza/ingrediant12num", {
          mode: 'cors',
          ingediant12num: newingediant12num,
          id: id,
        }).then(
          (response) => {
            setRecipieslist(recipieslist.map((val) => {
              return val.id === id ? {
                recipiename: val.recipiename,
                id: val.id,
                measurement1: val.measurement1,
                measurement2: val.measurement2,
                measurement3: val.measurement3,
                measurement4: val.measurement4,
                measurement5: val.measurement5,
                measurement6: val.measurement6,
                measurement7: val.measurement7,
                measurement8: val.measurement8,
                measurement9: val.measurement9,
                measurement10: val.measurement10,
                measurement11: val.measurement11,
                measurement12: val.measurement12,
                measurement13: val.measurement13,
                measurement14: val.measurement14,
                measurement15: val.measurement15,
                measurement16: val.measurement16,
                measurement17: val.measurement17,
                measurement18: val.measurement18,
                measurement19: val.measurement19,
                measurement20: val.measurement20,
                ingediant1: val.ingediant1,
                ingediant2: val.ingediant2,
                ingediant3: val.ingediant3,
                ingediant4: val.ingediant4,
                ingediant5: val.ingediant5,
                ingediant6: val.ingediant6,
                ingediant7: val.ingediant7,
                ingediant8: val.ingediant8,
                ingediant9: val.ingediant9,
                ingediant10: val.ingediant10,
                ingediant11: val.ingediant11,
                ingediant12: val.ingediant12,
                ingediant13: val.ingediant13,
                ingediant14: val.ingediant14,
                ingediant15: val.ingediant15,
                ingediant16: val.ingediant16,
                ingediant17: val.ingediant17,
                ingediant18: val.ingediant18,
                ingediant19: val.ingediant19,
                ingediant20: val.ingediant20,
                ingediant1num: val.ingediant1num,
                ingediant2num: val.ingediant2num,
                ingediant3num: val.ingediant3num,
                ingediant4num: val.ingediant4num,
                ingediant5num: val.ingediant5num,
                ingediant6num: val.ingediant6num,
                ingediant7num: val.ingediant7num,
                ingediant8num: val.ingediant8num,
                ingediant9num: val.ingediant9num,
                ingediant10num: val.ingediant10num,
                ingediant11num: val.ingediant11num,
                ingediant12num: newingediant12num,
                ingediant13num: val.ingediant13num,
                ingediant14num: val.ingediant14num,
                ingediant15num: val.ingediant15num,
                ingediant16num: val.ingediant16num,
                ingediant17num: val.ingediant17num,
                ingediant18num: val.ingediant18num,
                ingediant19num: val.ingediant19num,
                ingediant20num: val.ingediant20num,
                notes: val.notes,
                imglink: val.imglink
              } : val
            }));
          }
        );
      };
      const updateIngediant13num = async (id) => {
        await axiosPrivate.put("apizza/ingrediant13num", {
          mode: 'cors',
          ingediant13num: newingediant13num,
          id: id,
        }).then(
          (response) => {
            setRecipieslist(recipieslist.map((val) => {
              return val.id === id ? {
                recipiename: val.recipiename,
                id: val.id,
                measurement1: val.measurement1,
                measurement2: val.measurement2,
                measurement3: val.measurement3,
                measurement4: val.measurement4,
                measurement5: val.measurement5,
                measurement6: val.measurement6,
                measurement7: val.measurement7,
                measurement8: val.measurement8,
                measurement9: val.measurement9,
                measurement10: val.measurement10,
                measurement11: val.measurement11,
                measurement12: val.measurement12,
                measurement13: val.measurement13,
                measurement14: val.measurement14,
                measurement15: val.measurement15,
                measurement16: val.measurement16,
                measurement17: val.measurement17,
                measurement18: val.measurement18,
                measurement19: val.measurement19,
                measurement20: val.measurement20,
                ingediant1: val.ingediant1,
                ingediant2: val.ingediant2,
                ingediant3: val.ingediant3,
                ingediant4: val.ingediant4,
                ingediant5: val.ingediant5,
                ingediant6: val.ingediant6,
                ingediant7: val.ingediant7,
                ingediant8: val.ingediant8,
                ingediant9: val.ingediant9,
                ingediant10: val.ingediant10,
                ingediant11: val.ingediant11,
                ingediant12: val.ingediant12,
                ingediant13: val.ingediant13,
                ingediant14: val.ingediant14,
                ingediant15: val.ingediant15,
                ingediant16: val.ingediant16,
                ingediant17: val.ingediant17,
                ingediant18: val.ingediant18,
                ingediant19: val.ingediant19,
                ingediant20: val.ingediant20,
                ingediant1num: val.ingediant1num,
                ingediant2num: val.ingediant2num,
                ingediant3num: val.ingediant3num,
                ingediant4num: val.ingediant4num,
                ingediant5num: val.ingediant5num,
                ingediant6num: val.ingediant6num,
                ingediant7num: val.ingediant7num,
                ingediant8num: val.ingediant8num,
                ingediant9num: val.ingediant9num,
                ingediant10num: val.ingediant10num,
                ingediant11num: val.ingediant11num,
                ingediant12num: val.ingediant12num,
                ingediant13num: newingediant13num,
                ingediant14num: val.ingediant14num,
                ingediant15num: val.ingediant15num,
                ingediant16num: val.ingediant16num,
                ingediant17num: val.ingediant17num,
                ingediant18num: val.ingediant18num,
                ingediant19num: val.ingediant19num,
                ingediant20num: val.ingediant20num,
                notes: val.notes,
                imglink: val.imglink
              } : val
            }));
          }
        );
      };
      const updateIngediant14num = async (id) => {
        await axiosPrivate.put("apizza/ingrediant14num", {
          mode: 'cors',
          ingediant14num: newingediant14num,
          id: id,
        }).then(
          (response) => {
            setRecipieslist(recipieslist.map((val) => {
              return val.id === id ? {
                recipiename: val.recipiename,
                id: val.id,
                measurement1: val.measurement1,
                measurement2: val.measurement2,
                measurement3: val.measurement3,
                measurement4: val.measurement4,
                measurement5: val.measurement5,
                measurement6: val.measurement6,
                measurement7: val.measurement7,
                measurement8: val.measurement8,
                measurement9: val.measurement9,
                measurement10: val.measurement10,
                measurement11: val.measurement11,
                measurement12: val.measurement12,
                measurement13: val.measurement13,
                measurement14: val.measurement14,
                measurement15: val.measurement15,
                measurement16: val.measurement16,
                measurement17: val.measurement17,
                measurement18: val.measurement18,
                measurement19: val.measurement19,
                measurement20: val.measurement20,
                ingediant1: val.ingediant1,
                ingediant2: val.ingediant2,
                ingediant3: val.ingediant3,
                ingediant4: val.ingediant4,
                ingediant5: val.ingediant5,
                ingediant6: val.ingediant6,
                ingediant7: val.ingediant7,
                ingediant8: val.ingediant8,
                ingediant9: val.ingediant9,
                ingediant10: val.ingediant10,
                ingediant11: val.ingediant11,
                ingediant12: val.ingediant12,
                ingediant13: val.ingediant13,
                ingediant14: val.ingediant14,
                ingediant15: val.ingediant15,
                ingediant16: val.ingediant16,
                ingediant17: val.ingediant17,
                ingediant18: val.ingediant18,
                ingediant19: val.ingediant19,
                ingediant20: val.ingediant20,
                ingediant1num: val.ingediant1num,
                ingediant2num: val.ingediant2num,
                ingediant3num: val.ingediant3num,
                ingediant4num: val.ingediant4num,
                ingediant5num: val.ingediant5num,
                ingediant6num: val.ingediant6num,
                ingediant7num: val.ingediant7num,
                ingediant8num: val.ingediant8num,
                ingediant9num: val.ingediant9num,
                ingediant10num: val.ingediant10num,
                ingediant11num: val.ingediant11num,
                ingediant12num: val.ingediant12num,
                ingediant13num: val.ingediant13num,
                ingediant14num: newingediant14num,
                ingediant15num: val.ingediant15num,
                ingediant16num: val.ingediant16num,
                ingediant17num: val.ingediant17num,
                ingediant18num: val.ingediant18num,
                ingediant19num: val.ingediant19num,
                ingediant20num: val.ingediant20num,
                notes: val.notes,
                imglink: val.imglink
              } : val
            }));
          }
        );
      };
      const updateIngediant15num = async (id) => {
        await axiosPrivate.put("apizza/ingrediant15num", {
          mode: 'cors',
          ingediant15num: newingediant15num,
          id: id,
        }).then(
          (response) => {
            setRecipieslist(recipieslist.map((val) => {
              return val.id === id ? {
                recipiename: val.recipiename,
                id: val.id,
                measurement1: val.measurement1,
                measurement2: val.measurement2,
                measurement3: val.measurement3,
                measurement4: val.measurement4,
                measurement5: val.measurement5,
                measurement6: val.measurement6,
                measurement7: val.measurement7,
                measurement8: val.measurement8,
                measurement9: val.measurement9,
                measurement10: val.measurement10,
                measurement11: val.measurement11,
                measurement12: val.measurement12,
                measurement13: val.measurement13,
                measurement14: val.measurement14,
                measurement15: val.measurement15,
                measurement16: val.measurement16,
                measurement17: val.measurement17,
                measurement18: val.measurement18,
                measurement19: val.measurement19,
                measurement20: val.measurement20,
                ingediant1: val.ingediant1,
                ingediant2: val.ingediant2,
                ingediant3: val.ingediant3,
                ingediant4: val.ingediant4,
                ingediant5: val.ingediant5,
                ingediant6: val.ingediant6,
                ingediant7: val.ingediant7,
                ingediant8: val.ingediant8,
                ingediant9: val.ingediant9,
                ingediant10: val.ingediant10,
                ingediant11: val.ingediant11,
                ingediant12: val.ingediant12,
                ingediant13: val.ingediant13,
                ingediant14: val.ingediant14,
                ingediant15: val.ingediant15,
                ingediant16: val.ingediant16,
                ingediant17: val.ingediant17,
                ingediant18: val.ingediant18,
                ingediant19: val.ingediant19,
                ingediant20: val.ingediant20,
                ingediant1num: val.ingediant1num,
                ingediant2num: val.ingediant2num,
                ingediant3num: val.ingediant3num,
                ingediant4num: val.ingediant4num,
                ingediant5num: val.ingediant5num,
                ingediant6num: val.ingediant6num,
                ingediant7num: val.ingediant7num,
                ingediant8num: val.ingediant8num,
                ingediant9num: val.ingediant9num,
                ingediant10num: val.ingediant10num,
                ingediant11num: val.ingediant11num,
                ingediant12num: val.ingediant12num,
                ingediant13num: val.ingediant13num,
                ingediant14num: val.ingediant14num,
                ingediant15num: newingediant15num,
                ingediant16num: val.ingediant16num,
                ingediant17num: val.ingediant17num,
                ingediant18num: val.ingediant18num,
                ingediant19num: val.ingediant19num,
                ingediant20num: val.ingediant20num,
                notes: val.notes,
                imglink: val.imglink
              } : val
            }));
          }
        );
      };
      const updateIngediant16num = async (id) => {
        await axiosPrivate.put("apizza/ingrediant16num", {
          mode: 'cors',
          ingediant16num: newingediant16num,
          id: id,
        }).then(
          (response) => {
            setRecipieslist(recipieslist.map((val) => {
              return val.id === id ? {
                recipiename: val.recipiename,
                id: val.id,
                measurement1: val.measurement1,
                measurement2: val.measurement2,
                measurement3: val.measurement3,
                measurement4: val.measurement4,
                measurement5: val.measurement5,
                measurement6: val.measurement6,
                measurement7: val.measurement7,
                measurement8: val.measurement8,
                measurement9: val.measurement9,
                measurement10: val.measurement10,
                measurement11: val.measurement11,
                measurement12: val.measurement12,
                measurement13: val.measurement13,
                measurement14: val.measurement14,
                measurement15: val.measurement15,
                measurement16: val.measurement16,
                measurement17: val.measurement17,
                measurement18: val.measurement18,
                measurement19: val.measurement19,
                measurement20: val.measurement20,
                ingediant1: val.ingediant1,
                ingediant2: val.ingediant2,
                ingediant3: val.ingediant3,
                ingediant4: val.ingediant4,
                ingediant5: val.ingediant5,
                ingediant6: val.ingediant6,
                ingediant7: val.ingediant7,
                ingediant8: val.ingediant8,
                ingediant9: val.ingediant9,
                ingediant10: val.ingediant10,
                ingediant11: val.ingediant11,
                ingediant12: val.ingediant12,
                ingediant13: val.ingediant13,
                ingediant14: val.ingediant14,
                ingediant15: val.ingediant15,
                ingediant16: val.ingediant16,
                ingediant17: val.ingediant17,
                ingediant18: val.ingediant18,
                ingediant19: val.ingediant19,
                ingediant20: val.ingediant20,
                ingediant1num: val.ingediant1num,
                ingediant2num: val.ingediant2num,
                ingediant3num: val.ingediant3num,
                ingediant4num: val.ingediant4num,
                ingediant5num: val.ingediant5num,
                ingediant6num: val.ingediant6num,
                ingediant7num: val.ingediant7num,
                ingediant8num: val.ingediant8num,
                ingediant9num: val.ingediant9num,
                ingediant10num: val.ingediant10num,
                ingediant11num: val.ingediant11num,
                ingediant12num: val.ingediant12num,
                ingediant13num: val.ingediant13num,
                ingediant14num: val.ingediant14num,
                ingediant15num: val.ingediant15num,
                ingediant16num: newingediant16num,
                ingediant17num: val.ingediant17num,
                ingediant18num: val.ingediant18num,
                ingediant19num: val.ingediant19num,
                ingediant20num: val.ingediant20num,
                notes: val.notes,
                imglink: val.imglink
              } : val
            }));
          }
        );
      };
      const updateIngediant17num = async (id) => {
        await axiosPrivate.put("apizza/ingrediant17num", {
          mode: 'cors',
          ingediant17num: newingediant17num,
          id: id,
        }).then(
          (response) => {
            setRecipieslist(recipieslist.map((val) => {
              return val.id === id ? {
                recipiename: val.recipiename,
                id: val.id,
                measurement1: val.measurement1,
                measurement2: val.measurement2,
                measurement3: val.measurement3,
                measurement4: val.measurement4,
                measurement5: val.measurement5,
                measurement6: val.measurement6,
                measurement7: val.measurement7,
                measurement8: val.measurement8,
                measurement9: val.measurement9,
                measurement10: val.measurement10,
                measurement11: val.measurement11,
                measurement12: val.measurement12,
                measurement13: val.measurement13,
                measurement14: val.measurement14,
                measurement15: val.measurement15,
                measurement16: val.measurement16,
                measurement17: val.measurement17,
                measurement18: val.measurement18,
                measurement19: val.measurement19,
                measurement20: val.measurement20,
                ingediant1: val.ingediant1,
                ingediant2: val.ingediant2,
                ingediant3: val.ingediant3,
                ingediant4: val.ingediant4,
                ingediant5: val.ingediant5,
                ingediant6: val.ingediant6,
                ingediant7: val.ingediant7,
                ingediant8: val.ingediant8,
                ingediant9: val.ingediant9,
                ingediant10: val.ingediant10,
                ingediant11: val.ingediant11,
                ingediant12: val.ingediant12,
                ingediant13: val.ingediant13,
                ingediant14: val.ingediant14,
                ingediant15: val.ingediant15,
                ingediant16: val.ingediant16,
                ingediant17: val.ingediant17,
                ingediant18: val.ingediant18,
                ingediant19: val.ingediant19,
                ingediant20: val.ingediant20,
                ingediant1num: val.ingediant1num,
                ingediant2num: val.ingediant2num,
                ingediant3num: val.ingediant3num,
                ingediant4num: val.ingediant4num,
                ingediant5num: val.ingediant5num,
                ingediant6num: val.ingediant6num,
                ingediant7num: val.ingediant7num,
                ingediant8num: val.ingediant8num,
                ingediant9num: val.ingediant9num,
                ingediant10num: val.ingediant10num,
                ingediant11num: val.ingediant11num,
                ingediant12num: val.ingediant12num,
                ingediant13num: val.ingediant13num,
                ingediant14num: val.ingediant14num,
                ingediant15num: val.ingediant15num,
                ingediant16num: val.ingediant16num,
                ingediant17num: newingediant17num,
                ingediant18num: val.ingediant18num,
                ingediant19num: val.ingediant19num,
                ingediant20num: val.ingediant20num,
                notes: val.notes,
                imglink: val.imglink
              } : val
            }));
          }
        );
      };
      const updateIngediant18num = async (id) => {
        await axiosPrivate.put("apizza/ingrediant18num", {
          mode: 'cors',
          ingediant18num: newingediant18num,
          id: id,
        }).then(
          (response) => {
            setRecipieslist(recipieslist.map((val) => {
              return val.id === id ? {
                recipiename: val.recipiename,
                id: val.id,
                measurement1: val.measurement1,
                measurement2: val.measurement2,
                measurement3: val.measurement3,
                measurement4: val.measurement4,
                measurement5: val.measurement5,
                measurement6: val.measurement6,
                measurement7: val.measurement7,
                measurement8: val.measurement8,
                measurement9: val.measurement9,
                measurement10: val.measurement10,
                measurement11: val.measurement11,
                measurement12: val.measurement12,
                measurement13: val.measurement13,
                measurement14: val.measurement14,
                measurement15: val.measurement15,
                measurement16: val.measurement16,
                measurement17: val.measurement17,
                measurement18: val.measurement18,
                measurement19: val.measurement19,
                measurement20: val.measurement20,
                ingediant1: val.ingediant1,
                ingediant2: val.ingediant2,
                ingediant3: val.ingediant3,
                ingediant4: val.ingediant4,
                ingediant5: val.ingediant5,
                ingediant6: val.ingediant6,
                ingediant7: val.ingediant7,
                ingediant8: val.ingediant8,
                ingediant9: val.ingediant9,
                ingediant10: val.ingediant10,
                ingediant11: val.ingediant11,
                ingediant12: val.ingediant12,
                ingediant13: val.ingediant13,
                ingediant14: val.ingediant14,
                ingediant15: val.ingediant15,
                ingediant16: val.ingediant16,
                ingediant17: val.ingediant17,
                ingediant18: val.ingediant18,
                ingediant19: val.ingediant19,
                ingediant20: val.ingediant20,
                ingediant1num: val.ingediant1num,
                ingediant2num: val.ingediant2num,
                ingediant3num: val.ingediant3num,
                ingediant4num: val.ingediant4num,
                ingediant5num: val.ingediant5num,
                ingediant6num: val.ingediant6num,
                ingediant7num: val.ingediant7num,
                ingediant8num: val.ingediant8num,
                ingediant9num: val.ingediant9num,
                ingediant10num: val.ingediant10num,
                ingediant11num: val.ingediant11num,
                ingediant12num: val.ingediant12num,
                ingediant13num: val.ingediant13num,
                ingediant14num: val.ingediant14num,
                ingediant15num: val.ingediant15num,
                ingediant16num: val.ingediant16num,
                ingediant17num: val.ingediant17num,
                ingediant18num: newingediant18num,
                ingediant19num: val.ingediant19num,
                ingediant20num: val.ingediant20num,
                notes: val.notes,
                imglink: val.imglink
              } : val
            }));
          }
        );
      };
      const updateIngediant19num = async (id) => {
        await axiosPrivate.put("apizza/ingrediant19num", {
          mode: 'cors',
          ingediant19num: newingediant19num,
          id: id,
        }).then(
          (response) => {
            setRecipieslist(recipieslist.map((val) => {
              return val.id === id ? {
                recipiename: val.recipiename,
                id: val.id,
                measurement1: val.measurement1,
                measurement2: val.measurement2,
                measurement3: val.measurement3,
                measurement4: val.measurement4,
                measurement5: val.measurement5,
                measurement6: val.measurement6,
                measurement7: val.measurement7,
                measurement8: val.measurement8,
                measurement9: val.measurement9,
                measurement10: val.measurement10,
                measurement11: val.measurement11,
                measurement12: val.measurement12,
                measurement13: val.measurement13,
                measurement14: val.measurement14,
                measurement15: val.measurement15,
                measurement16: val.measurement16,
                measurement17: val.measurement17,
                measurement18: val.measurement18,
                measurement19: val.measurement19,
                measurement20: val.measurement20,
                ingediant1: val.ingediant1,
                ingediant2: val.ingediant2,
                ingediant3: val.ingediant3,
                ingediant4: val.ingediant4,
                ingediant5: val.ingediant5,
                ingediant6: val.ingediant6,
                ingediant7: val.ingediant7,
                ingediant8: val.ingediant8,
                ingediant9: val.ingediant9,
                ingediant10: val.ingediant10,
                ingediant11: val.ingediant11,
                ingediant12: val.ingediant12,
                ingediant13: val.ingediant13,
                ingediant14: val.ingediant14,
                ingediant15: val.ingediant15,
                ingediant16: val.ingediant16,
                ingediant17: val.ingediant17,
                ingediant18: val.ingediant18,
                ingediant19: val.ingediant19,
                ingediant20: val.ingediant20,
                ingediant1num: val.ingediant1num,
                ingediant2num: val.ingediant2num,
                ingediant3num: val.ingediant3num,
                ingediant4num: val.ingediant4num,
                ingediant5num: val.ingediant5num,
                ingediant6num: val.ingediant6num,
                ingediant7num: val.ingediant7num,
                ingediant8num: val.ingediant8num,
                ingediant9num: val.ingediant9num,
                ingediant10num: val.ingediant10num,
                ingediant11num: val.ingediant11num,
                ingediant12num: val.ingediant12num,
                ingediant13num: val.ingediant13num,
                ingediant14num: val.ingediant14num,
                ingediant15num: val.ingediant15num,
                ingediant16num: val.ingediant16num,
                ingediant17num: val.ingediant17num,
                ingediant18num: val.ingediant18num,
                ingediant19num: val.ingediant19num,
                ingediant20num: val.ingediant20num,
                notes: val.notes,
                imglink: val.imglink
              } : val
            }));
          }
        );
      };
      const updateIngediant20num = async (id) => {
        await axiosPrivate.put("apizza/ingrediant20num", {
          mode: 'cors',
          ingediant20num: newingediant20num,
          id: id,
        }).then(
          (response) => {
            setRecipieslist(recipieslist.map((val) => {
              return val.id === id ? {
                recipiename: val.recipiename,
                id: val.id,
                measurement1: val.measurement1,
                measurement2: val.measurement2,
                measurement3: val.measurement3,
                measurement4: val.measurement4,
                measurement5: val.measurement5,
                measurement6: val.measurement6,
                measurement7: val.measurement7,
                measurement8: val.measurement8,
                measurement9: val.measurement9,
                measurement10: val.measurement10,
                measurement11: val.measurement11,
                measurement12: val.measurement12,
                measurement13: val.measurement13,
                measurement14: val.measurement14,
                measurement15: val.measurement15,
                measurement16: val.measurement16,
                measurement17: val.measurement17,
                measurement18: val.measurement18,
                measurement19: val.measurement19,
                measurement20: val.measurement20,
                ingediant1: val.ingediant1,
                ingediant2: val.ingediant2,
                ingediant3: val.ingediant3,
                ingediant4: val.ingediant4,
                ingediant5: val.ingediant5,
                ingediant6: val.ingediant6,
                ingediant7: val.ingediant7,
                ingediant8: val.ingediant8,
                ingediant9: val.ingediant9,
                ingediant10: val.ingediant10,
                ingediant11: val.ingediant11,
                ingediant12: val.ingediant12,
                ingediant13: val.ingediant13,
                ingediant14: val.ingediant14,
                ingediant15: val.ingediant15,
                ingediant16: val.ingediant16,
                ingediant17: val.ingediant17,
                ingediant18: val.ingediant18,
                ingediant19: val.ingediant19,
                ingediant20: val.ingediant20,
                ingediant1num: val.ingediant1num,
                ingediant2num: val.ingediant2num,
                ingediant3num: val.ingediant3num,
                ingediant4num: val.ingediant4num,
                ingediant5num: val.ingediant5num,
                ingediant6num: val.ingediant6num,
                ingediant7num: val.ingediant7num,
                ingediant8num: val.ingediant8num,
                ingediant9num: val.ingediant9num,
                ingediant10num: val.ingediant10num,
                ingediant11num: val.ingediant11num,
                ingediant12num: val.ingediant12num,
                ingediant13num: val.ingediant13num,
                ingediant14num: val.ingediant14num,
                ingediant15num: val.ingediant15num,
                ingediant16num: val.ingediant16num,
                ingediant17num: val.ingediant17num,
                ingediant18num: val.ingediant18num,
                ingediant19num: val.ingediant19num,
                ingediant20num: newingediant20num,
                notes: val.notes,
                imglink: val.imglink
              } : val
            }));
          }
        );
      };
      
    
      const updateNotes = async (id) => {
        await axiosPrivate.put("apizza/recipenotes", {
          mode: 'cors',
          notes: newnotes,
          id: id,
        }).then(
          (response) => {
            setRecipieslist(recipieslist.map((val, key) => {
              return val.id === id ? {
                recipiename: val.recipiename,
                id: val.id,
                measurement1: val.measurement1,
                measurement2: val.measurement2,
                measurement3: val.measurement3,
                measurement4: val.measurement4,
                measurement5: val.measurement5,
                measurement6: val.measurement6,
                measurement7: val.measurement7,
                measurement8: val.measurement8,
                measurement9: val.measurement9,
                measurement10: val.measurement10,
                measurement11: val.measurement11,
                measurement12: val.measurement12,
                measurement13: val.measurement13,
                measurement14: val.measurement14,
                measurement15: val.measurement15,
                measurement16: val.measurement16,
                measurement17: val.measurement17,
                measurement18: val.measurement18,
                measurement19: val.measurement19,
                measurement20: val.measurement20,
                ingediant1: val.ingediant1,
                ingediant2: val.ingediant2,
                ingediant3: val.ingediant3,
                ingediant4: val.ingediant4,
                ingediant5: val.ingediant5,
                ingediant6: val.ingediant6,
                ingediant7: val.ingediant7,
                ingediant8: val.ingediant8,
                ingediant9: val.ingediant9,
                ingediant10: val.ingediant10,
                ingediant11: val.ingediant11,
                ingediant12: val.ingediant12,
                ingediant13: val.ingediant13,
                ingediant14: val.ingediant14,
                ingediant15: val.ingediant15,
                ingediant16: val.ingediant16,
                ingediant17: val.ingediant17,
                ingediant18: val.ingediant18,
                ingediant19: val.ingediant19,
                ingediant20: val.ingediant20,
                ingediant1num: val.ingediant1num,
                ingediant2num: val.ingediant2num,
                ingediant3num: val.ingediant3num,
                ingediant4num: val.ingediant4num,
                ingediant5num: val.ingediant5num,
                ingediant6num: val.ingediant6num,
                ingediant7num: val.ingediant7num,
                ingediant8num: val.ingediant8num,
                ingediant9num: val.ingediant9num,
                ingediant10num: val.ingediant10num,
                ingediant11num: val.ingediant11num,
                ingediant12num: val.ingediant12num,
                ingediant13num: val.ingediant13num,
                ingediant14num: val.ingediant14num,
                ingediant15num: val.ingediant15num,
                ingediant16num: val.ingediant16num,
                ingediant17num: val.ingediant17num,
                ingediant18num: val.ingediant18num,
                ingediant19num: val.ingediant19num,
                ingediant20num: val.ingediant20num,
                notes: newnotes,
                imglink: val.imglink
              } : val
            }));
          }
        );
      };
    
  const confirmDelete = async (id) => {
    const confirmAction = window.confirm("Are you sure to execute this action?");
    if (confirmAction) {


      alert("Action successfully executed");
      await axiosPrivate.delete(`/recipies/${id}`, {
        id: id,
      }).then((response) => {
        setRecipieslist(
          recipieslist.filter((val) => {
            return val.id !== id;
          }));
      }
      );


    } else {
      alert("Action canceled");
    }
  }

  const successCallback = (position) => {
      console.log(position);
      setAccessLocation(position.coords.latitude);
      setAccessLocation2(position.coords.longitude);
      console.log(accesslocation);
      console.log(accesslocation2);

  }
  const errorCallback = (error) => {
      console.error(error);
  };
  const options = {
      enableHighAccuracy: true,
      timeout: Infinity,
      maximumAge: Infinity
    };
  navigator.geolocation.watchPosition(successCallback, errorCallback, options)
 

      const locationlock = () => {
  
      
        if (( (accesslocation >  43.0507 && accesslocation < 43.0538 && Math.abs(accesslocation2) < 76.1553 && Math.abs(accesslocation2) > 76.1130) 
          ||  (accesslocation >  43.0460 && accesslocation < 43.0486 && Math.abs(accesslocation2) < 76.1175 && Math.abs(accesslocation2) > 76.0850)) ){
             console.log('welcome')
          console.log(accesslocation)
          console.log(Math.abs(accesslocation2))
          
        } else {
            navigate('/locationlock')
    console.log('what?')
    console.log(accesslocation2)
    console.log(accesslocation)
  
    
        }
    
      }


      
    return{
        recipiename, setRecipiename, getRecipies, recipieslist, setRecipieslist, 
        measurement1, setMeasurement1, measurement2, 
        setMeasurement2, measurement3, setMeasurement3, measurement4, setMeasurement4,
        measurement5, setMeasurement5, measurement6, 
        setMeasurement6, measurement7, setMeasurement7, measurement8, setMeasurement8,
        measurement9, setMeasurement9, measurement10, 
        setMeasurement10, measurement11, setMeasurement11, measurement12, setMeasurement12,
        measurement13, setMeasurement13, measurement14, 
        setMeasurement14, measurement15, setMeasurement15, measurement16, setMeasurement16,
        measurement17, setMeasurement17, measurement18, 
        setMeasurement18, measurement19, setMeasurement19, measurement20, setMeasurement20,
        ingediant1, setIngediant1, ingediant2, 
        setIngediant2, ingediant3, setIngediant3, ingediant4, setIngediant4,
        ingediant5, setIngediant5, ingediant6, 
        setIngediant6, ingediant7, setIngediant7, ingediant8, setIngediant8,
        ingediant9, setIngediant9, ingediant10, 
        setIngediant10, ingediant11, setIngediant11, ingediant12, setIngediant12,
        ingediant13, setIngediant13, ingediant14, 
        setIngediant14, ingediant15, setIngediant15, ingediant16, setIngediant16,
        ingediant17, setIngediant17, ingediant18, 
        setIngediant18, ingediant19, setIngediant19, ingediant20, setIngediant20,
        ingediant1num, setIngediant1num, ingediant2num, 
        setIngediant2num, ingediant3num, setIngediant3num, ingediant4num, setIngediant4num,
        ingediant5num, setIngediant5num, ingediant6num, 
        setIngediant6num, ingediant7num, setIngediant7num, ingediant8num, setIngediant8num,
        ingediant9num, setIngediant9num, ingediant10num, 
        setIngediant10num, ingediant11num, setIngediant11num, ingediant12num, setIngediant12num,
        ingediant13num, setIngediant13num, ingediant14num, 
        setIngediant14num, ingediant15num, setIngediant15num, ingediant16num, setIngediant16num,
        ingediant17num, setIngediant17num, ingediant18num, 
        setIngediant18num, ingediant19num, setIngediant19num, ingediant20num, setIngediant20num,
        searchTerm, setSearchTerm,
        recipie, setRecipie,
        setImgLink,
        notes, setNotes,
        newnotes, setNewnotes,
        newrecipiename, setNewrecipiename,
        times,setTimes,
        resetIngediantNumber,
        updateRecipiename,
        updateNotes,
        addRecipie,     buildname,
        buildId,
        buildDescription,
        buildimagepath,
        buildimagepath2,
        newIngediantNumber,
        confirmDelete,
        handleClose, addImglink,
        handleShow,
        updateIngediant1num, updateIngediant2num, updateIngediant3num, 
        updateIngediant4num, updateIngediant5num, updateIngediant6num,
        updateIngediant7num, updateIngediant8num, updateIngediant9num,
        updateIngediant10num, updateIngediant11num, updateIngediant12num,
        updateIngediant13num, updateIngediant14num, updateIngediant15num,
        updateIngediant16num, updateIngediant17num, updateIngediant18num,
        updateIngediant19num, updateIngediant20num,
        setNewingediant1num, setNewingediant2num, setNewingediant3num, setNewingediant4num,
        setNewingediant5num,setNewingediant6num,setNewingediant7num,
        setNewingediant8num,setNewingediant9num,setNewingediant10num,
        setNewingediant11num,setNewingediant12num,setNewingediant13num,
        setNewingediant14num,setNewingediant15num,setNewingediant16num,
        setNewingediant17num,setNewingediant18num,setNewingediant19num,
        setNewingediant20num, 
        show, setShow, getBuildprops, buildprops,
        getRecipie,addRecipieprops, setBuildname, setBuildId, setBuildimagepath,setBuildimagepath2,setBuildDescription
        


    }
}
export default useApizzarecipeUtils