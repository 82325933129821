import { useNavigate, Link } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthProvider";
import { Col, Container, Row} from 'react-bootstrap';
import { useState, useEffect } from 'react';
const Home = () => {
    const { setAuth } = useContext(AuthContext);
    const { setPersist } = useContext(AuthContext);
    const navigate = useNavigate();

    const logout = async () => {
        // if used in more components, this should be in context 
        // axios to /logout endpoint 
        setAuth({});
        setPersist();
        navigate('/login');
    }

    const welcoming ="Welcome to Recipe Lab! We love food and believe in its value in every day life. Here on Recipe Lab you can, Search up 10000 Recipes from our Edamae API! Use our map to compare prices at Local Grocery Stores and Get the best deals! Join in the FUN! Custom recipe shared digital recipe book!   ";
    const [eventData, setEventData] = useState([]);
    const [eventImg, setEventImg] = useState([]);
    const [eventTitle, setEventTitle] = useState([]);
    const [eventExplanation, setEventExplanation] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {

        const fetchEvents = async () => {
          setLoading(true)
          const res = await fetch('https://api.nasa.gov/planetary/apod?api_key=arCdC0QTFGT7JRRaBT26EtTNCXnvNQOm4Ch2bqnU')
          const { url, explanation, title, response } = await res.json()
          setEventTitle(title)
          setEventImg(url)
          setEventExplanation(explanation)
          setLoading(false)
          setEventData(response)
        }
        fetchEvents()
    
      }, [])

    return (
        <div>
            <Container>
                <Col>
   
        </Col>
        <Col>
          <div >

          {!loading ? <div id="btn">
            <Container>
            <Row className="row">
            <Col className='Col'>
            <section>
            <h1>Lounge</h1>
            <br />
       <p>{welcoming}</p>
            <br />
            <Link to="/editor">Go to the Editor page</Link>
            <br />
            <Link to="/admin">Go to the Admin page</Link>
            <br />
            <Link to="/recipes">Go to the Recipes</Link>
            <br />
            <Link to="/linkpage">Go to the link page</Link>
            <div className="flexGrow">
                <button onClick={logout}>Sign Out</button>
            </div>
        </section>
              <h1 className='titlenasa'>{eventTitle}</h1>
              
              
              
              <img title="YouTube video player" className='nasaimage' src={eventImg} alt=""></img>
              
            </Col>
          
            <Col className='Col'>
              <p className='Paragraph'>{eventExplanation}{eventData}</p>
          
            </Col>
            </Row>
            </Container>
          </div>
            : <h1>loading...</h1>}
          
          
          </div>
          </Col>
          </Container>
                </div>
    )
}

export default Home
