import '../App.css';
import { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Collapsible from 'react-collapsible';
import { Row, Col, Container } from 'react-bootstrap'
import useAxiosPrivate from "../hooks/useAxiosPrivate";

function Productpcform() {
    const axiosPrivate = useAxiosPrivate();
    const pceproductstory = ""



    const [searchTerm, setSearchTerm] = useState('');
    const [employee, setEmployee] = useState("");
    const [price, setPrice] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [brand, setBrand] = useState("")

    const [employeelist, setEmployeelist] = useState([]);

    const [newprice, setNewprice] = useState(0);
    const [newemployee, setNewemployee] = useState(0);


    const addEmployee = async () => {
        await axiosPrivate.post("/employees", {
            mode: 'cors',
            employee: employee,
            quantity: quantity,
            price: price,
            brand: brand,

        }).then(() => {
            setEmployeelist([
                ...employeelist,
                {
                    mode: 'cors',
                    employee: employee,
                    quantity: quantity,
                    price: price,
                    brand: brand,
                },
            ]);
        });
    };


    const getEmployees = async  () => {
        await axiosPrivate.get("/employees", {
            mode: 'cors',
        }).then((response) => {
            setEmployeelist(response.data);
        });
       };

 


    const deleteEmployee = async (id) => {

        await axiosPrivate.delete(`/employees/${id}`, {
            id: id,
        }).then((response) => {
            setEmployeelist(
                employeelist.filter((val) => {
                    return val.id !== id;
                }));
        }
        );
    };




    const updateEmployee = async (id) => {
        await axiosPrivate.put("/employees/productname", {
            mode: 'cors',
            employee: newemployee,
            id: id,
        }).then(
            (response) => {
                setEmployeelist(employeelist.map((val, key) => {
                    return val.id === id ? {

                        employee: newemployee,
                        id: val.id,
                        quantity: val.quantity,
                        price: val.price,
                        brand: val.brand,

                    } : val
                }));
            }
        );
    };


    const updatePrice = async (id) => {
        await axiosPrivate.put("/employees/productprice", {
            mode: 'cors',
            price: newprice,
            id: id,
        }).then(
            (response) => {
                setEmployeelist(employeelist.map((val, key) => {
                    return val.id === id ? {
                        employee: val.employee,
                        id: val.id,
                        quantity: val.quantity,
                        price: newprice,
                        brand: val.brand,
                    } : val
                }));
            }
        );
    };

    const changeColordata = () => {
        const btnd = document.getElementById('btnd');

        if (btnd.style.backgroundColor === 'silver') {
            btnd.style.backgroundColor = '#a0eeee';

            btnd.style.color = 'black';
        } else {
            btnd.style.backgroundColor = 'silver';

            btnd.style.color = 'black';

        }

    }

    return (
        <div id="btn" style={{ display: '', justifyContent: 'center' }} className="App2">
            <div>
                <p className='Paragraph'>{pceproductstory}</p>
            </div>


            <div className="information">

                <div className='information' style={{ display: 'flex-col', justifyContent: 'center' }} >
                    <label>employee</label>
                    <input type="text"
                        onChange={(event) => {
                            setEmployee(event.target.value);
                        }}
                    />


                    <label>quantity</label>
                    <input type="file"
                        onChange={(event) => {
                            setQuantity(event.target.value);
                        }}
                    />

                    <label>price</label>
                    <input type="text"
                        onChange={(event) => {
                            setPrice(event.target.value);
                        }}
                    />


                    <label>Brand</label>
                    <input type="text"
                        onChange={(event) => {
                            setBrand(event.target.value);
                        }}
                    />

                    <Container>
                        <Row className="row">
                            <Col>
                                <button className="button" onClick={addEmployee}>Add Employee</button>
                            </Col>
                            <Col>
                                <button className="button" onClick={getEmployees}>Inventory</button>
                            </Col></Row>
                    </Container>


                    <input
                        type="text"
                        placeholder="search..."
                        onChange={(event) => { setSearchTerm(event.target.value) }} />
                    <div>
                        <button onClick={changeColordata}>Not so bright please</button>
                    </div>
                </div>
            </div>
            <div id="btnd" className="employees d-flex align-content-start flex-wrap">
                {employeelist.filter((val) => {
                    if (searchTerm === "") {
                        return val
                    } else if (val.employee.toLowerCase().includes(searchTerm.toLowerCase())) {
                        return (val);
                    } else { return null }
                }).map((val) => {
                    return (
                        <div className="employee">
                            <code><li className='input'>employee:{val.employee}</li></code>
                            <code><li className='input'>Id:{val.id}</li></code>
                            <code><li className='input'>Quantity:{val.quantity}</li></code>
                            <code><li className='input'>Price:{val.price}</li></code>
                            <code><li className='input'>Brand:{val.brand}</li></code>

                            <div className='input'>
                                <Collapsible trigger="Update data">
                                    <input className="button" type="text" placeholder='Employee:' onChange={(event) => { setNewemployee(event.target.value); }} />
                                    <button onClick={() => { updateEmployee(val.id) }}>Update</button>

                                    <input className="button" type="text" placeholder='Price:' onChange={(event) => { setNewprice(event.target.value); }} />
                                    <button onClick={() => { updatePrice(val.id) }}>Update</button>




                                </Collapsible>
                            </div>
                            <button onClick={() => { deleteEmployee(val.id) }}>Delete</button>


                        </div>
                    );
                })}
            </div>

        </div>
    );
}

export default Productpcform;