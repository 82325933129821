import { useRef, useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Col, Container, Row} from 'react-bootstrap';
import axios from '../api/axios';
import '../index.css';
const LOGIN_URL = '/auth';
const { format } = require('date-fns');
const Login = () => {

    const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef();
    const errRef = useRef();

    
    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ user, pwd }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            

            console.log(JSON.stringify(response?.data));
           // locationlock();
            //console.log(JSON.stringify(response));
            const accessToken = response?.data?.accessToken;
            const roles = response?.data?.roles;
            setAuth({ user, pwd, roles, accessToken });
            setUser('');
            setPwd('');
            
            navigate(from, { replace: true });
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }

    useEffect(() => {

        const fetchEvents = async () => {
          setLoading(true)
          const res = await fetch('https://api.nasa.gov/planetary/apod?api_key=arCdC0QTFGT7JRRaBT26EtTNCXnvNQOm4Ch2bqnU')
          const { url, explanation, title, response } = await res.json()
          setEventTitle(title)
          setEventImg(url)
          setEventExplanation(explanation)
          setLoading(false)
          setEventData(response)
        }
        fetchEvents()
    
      }, [])
    
  
   
    const welcoming ="Welcome to Recipe Lab! We love food and believe in its value in every day life. Here on Recipe Lab you can, Search up 10000 Recipes from our Edamae API! Use our map to compare prices at Local Grocery Stores and Get the best deals! Join in the FUN! Custom recipe shared digital recipe book!   ";
    const [eventData, setEventData] = useState([]);
    const [eventImg, setEventImg] = useState([]);
    const [eventTitle, setEventTitle] = useState([]);
    const [eventExplanation, setEventExplanation] = useState([]);
    const [loading, setLoading] = useState(false);
 
    const dateTime = `${format(new Date(), 'EEEE')}`;
    

return (
<div id="btn"  className="App">
<h1 className='title'>RECIPE LAB</h1>

        <section >
        <Container><Col><Row>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            
            <p className='ParagraphA'>{welcoming}{eventData}</p>
            </Row></Col>
            </Container>
            <h1>Sign In</h1>
            <>{dateTime}</>
            <form onSubmit={handleSubmit}>
            <Container><Row><Col>
                <label htmlFor="username">Username:</label>
                <input
                    type="text"
                    id="username"
                    ref={userRef}
                    autoComplete="off"
                    onChange={(e) => setUser(e.target.value)}
                    value={user}
                    required
                />
</Col><Col>
                <label htmlFor="password">Password:</label>
                <input
                    type="password"
                    id="password"
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    required
                /><br/><br/>
                <button>Sign In</button>
                </Col></Row></Container>
            </form>
            
            <p>
                Need an Account?<br />
                <span className="line">
                    <Link to="/register">Sign Up</Link>
                </span>
            </p>
        </section>
            
        <div >

{!loading ? <div>
  <Container>
  <Row className="row">
  <Col className='Col'>
    <h1 className='titlenasa'>{eventTitle}</h1>
    
    
    
    <img title="YouTube video player" className='nasaimage' src={eventImg} alt=""></img>
  </Col>

  <Col className='Col'>
    <p className='Paragraph'>{eventExplanation}{eventData}</p>

  </Col>
  </Row>
  </Container>
</div>
  : <h1>loading...</h1>}


</div>
      </div>
        
        
    )
}

export default Login
