import React, { useEffect, useState } from 'react';
import Recipe from "../components/Recipe";

import '../App.css';

const Recipesearch = () => {
  const APP_ID = "6de7e769";
  const APP_KEY = "f2f625aa431690b486ad5886e2f923d1";

  const[recipes, setRecipes] = useState([]);
  const[search, setSearch] = useState("");
  const[query, setQuery] = useState('chicken');

  useEffect(() =>{



    const getRecipes = async () => {
        const response = await fetch(`https://api.edamam.com/search?q=${query}&app_id=${APP_ID}&app_key=${APP_KEY}`);
        const data = await response.json();
        setRecipes(data.hits);
        console.log(data.hits);
      };

    console.log("Effect has been run");
    getRecipes();
  }, [query]);


  const updateSearch = e => {
    setSearch(e.target.value);
  }

  const getSearch = e => {
    e.preventDefault(); //To stop page refresh
    setQuery(search);
  }

  return(
    <div id="btn" className="recipesearch"> 
      <div className='header'> <h1>Discover Recipes from Edamam!</h1> </div>
      <form onSubmit={getSearch} className="search-form">
        <input className="search-bar" type="text" value={search} onChange={updateSearch} placeholder="e.g., Chicken, salad, soup, chocolate"></input>
        <button className="search-button" type="submit">
          Search
        </button>
      </form>
      <div  className="recipes">
        {recipes.map(recipe =>(
          <Recipe 
            key={recipe.recipe.label} 
            title={recipe.recipe.label} 
            calories={recipe.recipe.calories} 
            image={recipe.recipe.image}
            ingredients={recipe.recipe.ingredients}
          />
        ))}
      </div>
    </div>
  );
};

export default Recipesearch;