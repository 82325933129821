import { Link } from "react-router-dom";


const Locationlock = () => {
    return (
        <section>
            <h1>where are you</h1>
            <br />
            <p>You must not be at apizza.</p>
            
            <div className="flexGrow">
                
                <Link to="/">Home</Link>
            </div>
        </section>
    )
}

export default Locationlock
