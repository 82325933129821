
import {useEffect} from "react";
import { useParams } from 'react-router-dom';
import Collapsible from 'react-collapsible';
import { Link } from "react-router-dom"
import { Nav } from 'react-bootstrap';
import useApizzarecipeutils from "../../utils/apizzaUtils";




const Utils = require('../../utils/utils');


    
const MasterBuild = (  ) => {
 
  const {
    getBuildprops , buildprops,     buildname,
    buildId,
    buildDescription,
    buildimagepath,
    buildimagepath2,
  } = useApizzarecipeutils();
  
  const params = useParams()
  const imglink = params.imglink
 
  useEffect(() => {
    async function fetchData(){
    getBuildprops(imglink);
  }
  fetchData();
   }, [imglink, getBuildprops])

 
  
    const buildimage = `https://artinventoryserver.herokuapp.com/images/${buildimagepath}`;
    const buildimage2 =  buildimagepath2 === null ? null :
     `https://artinventoryserver.herokuapp.com/images/${buildimagepath2}`;

  const { getRecipie, recipie, setTimes, 
    setNewrecipiename, setNewnotes, searchTerm, resetIngediantNumber, 
    newIngediantNumber, updateRecipiename, updateNotes, confirmDelete
        }= useApizzarecipeutils();


  
return (
    <div>
       
      <h3>
      {imglink} this is the variable<br/>
        
      </h3>
      {buildname}
      <p>{buildDescription}</p>
      <img   alt='' className='buildimage' src={buildimage}></img>
      <br/>
      <br/>
       {  buildprops.buildimagepath2 === null ? 
      null : <img   alt='' className='buildimage' src={buildimage2}></img> }
      <button className="buttonbook" onClick={() => { getRecipie(`${buildId}`) }}> Recipe </button>
      
      <div id="btnd" className="employees d-flex align-content-start flex-wrap">

        {recipie.filter((val) => {
          if (searchTerm === "") {
            return val
          } else if (val.recipiename.toLowerCase().includes(searchTerm.toLowerCase())) {
            return (val);
          } else { return null }
        }).map((val) => {
          return (

            <div id="btnd" >


              <div className="employee">
                <div className='input'>Recipe name:<br></br><strong className='rtitle'>{val.recipiename}</strong></div>
                {/*<div>
<form onSubmit={onSubmitHandler} >
<input type='file' onChange={fileChangeHandler} />
<button type='submit'>submit file to backend</button>
</form>
  </div>*
<button className="" onClick={getImages}>image</button>
{/* <ImgLoader/> 

        <div className='input'>id:{val.id}</div>*/}
                <div id="done" className='input'>
                  <strong className='inputT'></strong>{val.ingediant1num}
                  <br></br>{val.measurement1}
                  <div className='input2'>{val.ingediant1}

                    <button className="Buttoncheck" onClick={Utils.changeColordata}>Done</button>
                  </div>
                </div>
                <div id="done2" className='input'><strong className='inputT'></strong>{val.ingediant2num}
                  <br></br>{val.measurement2}<div className='input2'>{val.ingediant2}

                    <button className="Buttoncheck" onClick={Utils.changeColordata2}>Done</button>
                  </div>
                </div>
                <div id="done3" className='input'><strong className='inputT'></strong>{val.ingediant3num}<br></br>{val.measurement3}<div className='input2'>{val.ingediant3}

                  <button className="Buttoncheck" onClick={Utils.changeColordata3}>Done</button>
                </div>
                </div>
                <div id="done4" className='input'><strong className='inputT'></strong>{val.ingediant4num}<br></br>{val.measurement4}<div className='input2'>{val.ingediant4}

                  <button className="Buttoncheck" onClick={Utils.changeColordata4}>Done</button>
                </div>
                </div>
                { (val.ingediant5num === null || val.ingediant5num === 0) ? "no more ingrediants" :
        <Collapsible trigger="more ingredients">
       
          <div id="done5" className='input'><strong className='inputT'></strong>{val.ingediant5num}<br></br>{val.measurement5}<div className='input2'>{val.ingediant5}
          
                  <button className="Buttoncheck" onClick={Utils.changeColordata5}>Done</button>
            </div>
          </div>
          { (val.ingediant6num === null || val.ingediant6num === 0) ? null :
          <div id="done6" className='input'><strong className='inputT'></strong>{val.ingediant6num}<br></br>{val.measurement6}<div className='input2'>{val.ingediant6}
          
                  <button className="Buttoncheck" onClick={Utils.changeColordata6}>Done</button>
            </div>
          </div>}
          { (val.ingediant7num === null || val.ingediant7num === 0) ? null :
          <div id="done7" className='input'><strong className='inputT'></strong>{val.ingediant7num}<br></br>{val.measurement7}<div className='input2'>{val.ingediant7}
          
                  <button className="Buttoncheck" onClick={Utils.changeColordata7}>Done</button>
            </div>
          </div>}
          { (val.ingediant8num === null || val.ingediant8num === 0) ? null :
          <div id="done8" className='input'><strong className='inputT'></strong>{val.ingediant8num}<br></br>{val.measurement8}<div className='input2'>{val.ingediant8}
          
                  <button className="Buttoncheck" onClick={Utils.changeColordata8}>Done</button>
            </div>
          </div>}
          {(val.ingediant9num === null || val.ingediant9num === 0) ? null :
          <div id="done9" className='input'><strong className='inputT'></strong>{val.ingediant9num}<br></br>{val.measurement9}<div className='input2'>{val.ingediant9}
          
                  <button className="Buttoncheck" onClick={Utils.changeColordata9}>Done</button>
            </div>
          </div>}
          { (val.ingediant10num === null || val.ingediant10num === 0) ? null :
          <div id="done10" className='input'><strong className='inputT'></strong>{val.ingediant10num}<br></br>{val.measurement10}<div className='input2'>{val.ingediant10}
          
                  <button className="Buttoncheck" onClick={Utils.changeColordata10}>Done</button>
            </div>
          </div>}
          {  (val.ingediant11num === null || val.ingediant11num === 0)  ?  null :
           <div id="done11" className='input'><strong className='inputT'></strong>{val.ingediant11num}<br></br>{val.measurement11}<div className='input2'>{val.ingediant11}
          
              <button className="Buttoncheck" onClick={Utils.changeColordata11}>Done</button>
            </div>
          </div> }

        
          { (val.ingediant12num === null || val.ingediant12num === 0) ? null :
          <div id="done12" className='input'><strong className='inputT'></strong>{val.ingediant12num}<br></br>{val.measurement12}<div className='input2'>{val.ingediant12}
          
                  <button className="Buttoncheck" onClick={Utils.changeColordata12}>Done</button>
            </div>
          </div>}
          { (val.ingediant13num === null || val.ingediant13num === 0) ? null :
          <div id="done13" className='input'><strong className='inputT'></strong>{val.ingediant13num}<br></br>{val.measurement13}<div className='input2'>{val.ingediant13}
          
                  <button className="Buttoncheck" onClick={Utils.changeColordata13}>Done</button>
            </div>
          </div>}
          { (val.ingediant14num === null || val.ingediant14num === 0) ? null :
          <div id="done14" className='input'><strong className='inputT'></strong>{val.ingediant14num}<br></br>{val.measurement14}<div className='input2'>{val.ingediant14}
          
                  <button className="Buttoncheck" onClick={Utils.changeColordata14}>Done</button>
            </div>
          </div>}
          { (val.ingediant15num === null || val.ingediant15num === 0) ? null :
          <div id="done15" className='input'><strong className='inputT'></strong>{val.ingediant15num}<br></br>{val.measurement15}<div className='input2'>{val.ingediant15}
          
                  <button className="Buttoncheck" onClick={Utils.changeColordata15}>Done</button>
            </div>
          </div>}
          { (val.ingediant16num === null || val.ingediant16num === 0) ? null :
          <div id="done16" className='input'><strong className='inputT'></strong>{val.ingediant16num}<br></br>{val.measurement16}<div className='input2'>{val.ingediant16}
          
                  <button className="Buttoncheck" onClick={Utils.changeColordata16}>Done</button>
            </div>
          </div>}
          { (val.ingediant17num === null || val.ingediant17num === 0) ? null :
          <div id="done17" className='input'><strong className='inputT'></strong>{val.ingediant17num}<br></br>{val.measurement17}<div className='input2'>{val.ingediant17}
          
                  <button className="Buttoncheck" onClick={Utils.changeColordata17}>Done</button>
            </div>
          </div>}
          { (val.ingediant18num === null || val.ingediant18num === 0) ? null :
          <div id="done18" className='input'><strong className='inputT'></strong>{val.ingediant18num}<br></br>{val.measurement18}<div className='input2'>{val.ingediant18}
          
                  <button className="Buttoncheck" onClick={Utils.changeColordata18}>Done</button>
            </div>
          </div>}
          { (val.ingediant19num === null || val.ingediant19num === 0) ? null :
          <div id="done19" className='input'><strong className='inputT'></strong>{val.ingediant19num}<br></br>{val.measurement19}<div className='input2'>{val.ingediant19}
          
                  <button className="Buttoncheck" onClick={Utils.changeColordata19}>Done</button>
            </div>
          </div>}
          { (val.ingediant20num === null || val.ingediant20num === 0) ? null :
          <div id="done20" className='input'><strong className='inputT'></strong>{val.ingediant20num}<br></br>{val.measurement20}<div className='input2'>{val.ingediant20}
          
                  <button className="Buttoncheck" onClick={Utils.changeColordata20}>Done</button>
            </div>
          </div>}
          
        
        </Collapsible>}
                <div id="done2" className='input'>Image:<Nav.Link as={Link} to={`./${val.imglink}`}>Build Image</Nav.Link></div>
                <div id="done2" className='input'>Notes:{val.notes}</div>
                <div id="done2" className='input'>
                  <Collapsible trigger="Update data">
                    <input className="button" type="text" placeholder='Recipie name:...' onChange={(event) => { setNewrecipiename(event.target.value); }} />
                    <button onClick={() => { updateRecipiename(val.id) }}>Update</button>

                    <input className="button" type="text" placeholder='notes:...' onChange={(event) => { setNewnotes(event.target.value); }} />
                    <button onClick={() => { updateNotes(val.id) }}>Update</button>
                    <button onClick={() => { confirmDelete(val.id) }}>Delete</button>
                    <label>Times</label>
                    <input className="numinput" type="number"
                      onChange={(event) => {
                        setTimes(event.target.value);
                      }}
                    />
                    <button onClick={() => { newIngediantNumber(val.id) }}>multiply</button>
                    <button onClick={() => { resetIngediantNumber(val.id) }}>divide</button>
                    <button className="buttonbook" onClick={() => { getRecipie(val.id) }}>Reset</button>




                  </Collapsible>

                </div>






              </div>
            </div>
          );
        })}
      </div>
    </div>

  )
};




export default MasterBuild