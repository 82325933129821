import React from 'react'
import { useRef, useEffect } from 'react';

const { tableau } = window;


export const Tableau2 = () => {
    
    const ref = useRef(null);
    const url = 'https://public.tableau.com/views/Pc_erie/Sheet1?:language=en-US&:display_count=n&:origin=viz_share_link'
    const initViz = () => {
        new tableau.Viz(ref.current, url )
    };
    useEffect(initViz, []);

    return (
        <div className="tableinfo" id="vizContainer" ref={ref} />
            
        
    )
}


