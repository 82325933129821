

const changeColordatax = () => {
    const btnd = document.getElementById('btnd');
    if (btnd.style.backgroundColor === 'silver') {
      btnd.style.backgroundColor = 'aqua';
      btnd.style.color = '#b8c7cc';
    } else {
      btnd.style.backgroundColor = 'silver';
      btnd.style.color = '#b8c7cc';
    }
  }

  const changeColordata = () => {
    const done = document.getElementById('done');
    if (done.style.backgroundColor === 'green') {
      done.style.backgroundColor = 'red';
      done.style.color = '#b8c7cc';
    } else {
      done.style.backgroundColor = 'green';
      done.style.color = '#b8c7cc';
    }
  }
  const changeColordata2 = () => {
    const done2 = document.getElementById('done2');
   
    if (done2.style.backgroundColor === 'green') {
      done2.style.backgroundColor = 'red';

      done2.style.color = '#b8c7cc';
    } else {
      done2.style.backgroundColor = 'green';

      done2.style.color = '#b8c7cc';

    }

  }
  const changeColordata3 = () => {
    const done2 = document.getElementById('done3');
   
    if (done2.style.backgroundColor === 'green') {
      done2.style.backgroundColor = 'red';

      done2.style.color = '#b8c7cc';
    } else {
      done2.style.backgroundColor = 'green';

      done2.style.color = '#b8c7cc';

    }

  }

  const changeColordata4 = () => {
    const done2 = document.getElementById('done4');
   
    if (done2.style.backgroundColor === 'green') {
      done2.style.backgroundColor = 'red';

      done2.style.color = '#b8c7cc';
    } else {
      done2.style.backgroundColor = 'green';

      done2.style.color = '#b8c7cc';

    }

  }

  const changeColordata5 = () => {
    const done2 = document.getElementById('done5');
   
    if (done2.style.backgroundColor === 'green') {
      done2.style.backgroundColor = 'red';

      done2.style.color = '#b8c7cc';
    } else {
      done2.style.backgroundColor = 'green';

      done2.style.color = '#b8c7cc';

    }

  }

  const changeColordata6 = () => {
    const done2 = document.getElementById('done6');
   
    if (done2.style.backgroundColor === 'green') {
      done2.style.backgroundColor = 'red';

      done2.style.color = '#b8c7cc';
    } else {
      done2.style.backgroundColor = 'green';

      done2.style.color = '#b8c7cc';

    }

  }

  const changeColordata7 = () => {
    const done2 = document.getElementById('done7');
   
    if (done2.style.backgroundColor === 'green') {
      done2.style.backgroundColor = 'red';

      done2.style.color = '#b8c7cc';
    } else {
      done2.style.backgroundColor = 'green';

      done2.style.color = '#b8c7cc';

    }

  }

  const changeColordata8 = () => {
    const done2 = document.getElementById('done8');
   
    if (done2.style.backgroundColor === 'green') {
      done2.style.backgroundColor = 'red';

      done2.style.color = '#b8c7cc';
    } else {
      done2.style.backgroundColor = 'green';

      done2.style.color = '#b8c7cc';

    }

  }

  const changeColordata9 = () => {
    const done2 = document.getElementById('done9');
   
    if (done2.style.backgroundColor === 'green') {
      done2.style.backgroundColor = 'red';

      done2.style.color = '#b8c7cc';
    } else {
      done2.style.backgroundColor = 'green';

      done2.style.color = '#b8c7cc';

    }

  }
  const changeColordata10 = () => {
    const done2 = document.getElementById('done10');
   
    if (done2.style.backgroundColor === 'green') {
      done2.style.backgroundColor = 'red';

      done2.style.color = '#b8c7cc';
    } else {
      done2.style.backgroundColor = 'green';

      done2.style.color = '#b8c7cc';

    }

  }
  const changeColordata11 = () => {
    const done2 = document.getElementById('done11');
   
    if (done2.style.backgroundColor === 'green') {
      done2.style.backgroundColor = 'red';

      done2.style.color = '#b8c7cc';
    } else {
      done2.style.backgroundColor = 'green';

      done2.style.color = '#b8c7cc';

    }

  }
  const changeColordata12 = () => {
    const done2 = document.getElementById('done12');
   
    if (done2.style.backgroundColor === 'green') {
      done2.style.backgroundColor = 'red';

      done2.style.color = '#b8c7cc';
    } else {
      done2.style.backgroundColor = 'green';

      done2.style.color = '#b8c7cc';

    }

  }

  const changeColordata13 = () => {
    const done2 = document.getElementById('done13');
   
    if (done2.style.backgroundColor === 'green') {
      done2.style.backgroundColor = 'red';

      done2.style.color = '#b8c7cc';
    } else {
      done2.style.backgroundColor = 'green';

      done2.style.color = '#b8c7cc';

    }

  }
  const changeColordata14 = () => {
    const done2 = document.getElementById('done14');
   
    if (done2.style.backgroundColor === 'green') {
      done2.style.backgroundColor = 'red';

      done2.style.color = '#b8c7cc';
    } else {
      done2.style.backgroundColor = 'green';

      done2.style.color = '#b8c7cc';

    }

  }
  const changeColordata15 = () => {
    const done2 = document.getElementById('done15');
   
    if (done2.style.backgroundColor === 'green') {
      done2.style.backgroundColor = 'red';

      done2.style.color = '#b8c7cc';
    } else {
      done2.style.backgroundColor = 'green';

      done2.style.color = '#b8c7cc';

    }

  }
  const changeColordata16 = () => {
    const done2 = document.getElementById('done16');
   
    if (done2.style.backgroundColor === 'green') {
      done2.style.backgroundColor = 'red';

      done2.style.color = '#b8c7cc';
    } else {
      done2.style.backgroundColor = 'green';

      done2.style.color = '#b8c7cc';

    }

  }
  const changeColordata17 = () => {
    const done2 = document.getElementById('done17');
   
    if (done2.style.backgroundColor === 'green') {
      done2.style.backgroundColor = 'red';

      done2.style.color = '#b8c7cc';
    } else {
      done2.style.backgroundColor = 'green';

      done2.style.color = '#b8c7cc';

    }

  }
  const changeColordata18 = () => {
    const done2 = document.getElementById('done18');
   
    if (done2.style.backgroundColor === 'green') {
      done2.style.backgroundColor = 'red';

      done2.style.color = '#b8c7cc';
    } else {
      done2.style.backgroundColor = 'green';

      done2.style.color = '#b8c7cc';

    }

  }
  const changeColordata19 = () => {
    const done2 = document.getElementById('done19');
   
    if (done2.style.backgroundColor === 'green') {
      done2.style.backgroundColor = 'red';

      done2.style.color = '#b8c7cc';
    } else {
      done2.style.backgroundColor = 'green';

      done2.style.color = '#b8c7cc';

    }

  }
  const changeColordata20 = () => {
    const done2 = document.getElementById('done20');
   
    if (done2.style.backgroundColor === 'green') {
      done2.style.backgroundColor = 'red';

      done2.style.color = '#b8c7cc';
    } else {
      done2.style.backgroundColor = 'green';

      done2.style.color = '#b8c7cc';

    }

  }

  module.exports = {
    changeColordatax,
    changeColordata,
    changeColordata2,
    changeColordata3,
    changeColordata4,
    changeColordata5,
    changeColordata6,
    changeColordata7,
    changeColordata8,
    changeColordata9,
    changeColordata10,
    changeColordata11,
    changeColordata12,
    changeColordata13,
    changeColordata14,
    changeColordata15,
    changeColordata16,
    changeColordata17,
    changeColordata18,
    changeColordata19,
    changeColordata20
}