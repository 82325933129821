import useRecipeutils from "../utils/recipeUtils";
import { Link } from "react-router-dom"
import '../App.css';
import { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Collapsible from 'react-collapsible';
import { Row, Col, Container, Modal, Button,  Nav } from 'react-bootstrap'
const Utils = require('../utils/utils');

//import ImgLoader from './imgloader';

function Recipes() {
  
  // const {} = useRecipeutils("");
  const {
    recipieslist,
    searchTerm, setSearchTerm,
    getRecipies,setRecipiename,setMeasurement1,
    setMeasurement2, setMeasurement3, setMeasurement4,
    setMeasurement5, setMeasurement6, setMeasurement7, setMeasurement8,
    setMeasurement9, setMeasurement10, setMeasurement11, setMeasurement12,
    setIngediant1, setIngediant2, setIngediant3, setIngediant4,
    setIngediant5, setIngediant6, setIngediant7, setIngediant8,
    setIngediant9, setIngediant10, setIngediant11, setIngediant12,
    setIngediant1num, setIngediant2num, setIngediant3num, setIngediant4num,
    setIngediant5num, setIngediant6num, setIngediant7num, setIngediant8num,
    setIngediant9num, setIngediant10num, setIngediant11num, setIngediant12num,
    setNotes, setNewnotes, setNewrecipiename, setTimes,
    resetIngediantNumber, updateRecipiename, updateNotes,
    addRecipie,
    newIngediantNumber,
    confirmDelete,  addImglink, addRecipieprops,  setBuildname,  setBuildId,  setBuildDescription,
    buildimagepath,setBuildimagepath,  setBuildimagepath2,  show, handleClose , handleShow
  } = useRecipeutils();
  


  /*const [data, setData] = useState([]);
  useEffect(() => {
    axiosPrivate
      .get("http://localhost:3500/getimages")
      .then((res) => setData(res.data))
      .catch((err) => console.log(err, "it has an error"));
  });*/
//const [picture, setPicture ]= useState("");

  const recipestory = "Here you can add your own creations and save them for later use, it's even shared with everyone else! So you can try other peoples creations too! clicke' the show recipies button to see what's already here! Fill in the fields to Join in the fun and add your own Recipe! ";

  
  
  const [fileData, setFileData] = useState();

  const fileChangeHandler = (e) => {
    setFileData(e.target.files[0]);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    //hanle file data from the state before sending

    const data = new FormData();

    data.append('image', fileData)


    await fetch(`http://localhost:3500/single`, {
      method: "POST",
      body: data,
    })
      .then((result) => {
        console.log('file sent succesful');

        console.log(FormData);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  //end img


  
  const reader = new FileReader();
  reader.onloadend = () => {
    const base64data = reader.result;
    console.log(base64data);
  }

  return (
    <div id="btn" style={{ display: '', justifyContent: 'center' }} className="App2">
      <section>
        <h1>The Lounge</h1>
        <br />

        <div className="flexGrow">
          <Link to="/">Lounge</Link>
        </div>
      </section>
      <div>
        <p className='Paragraph'>{recipestory}</p>
      </div>
      <Container >
        <div className="information">
          <Col>
            <button className="buttonbook" onClick={()=>{getRecipies("/recipies")}}>Recipe book</button>
          </Col>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> Build Page Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>  

<div className='information' style={{ display: 'flex-column', justifyContent: 'center' }} >
<Row><Col>
  <div style={{ justifyContent: 'center' }} className="">

    <label >Build name</label>
    <input className="informationinput" type="text"
      onChange={(event) => {
        setBuildname(event.target.value);
      }}
    />
  </div>
  <div style={{ display: '', justifyContent: 'center' }} className="">

    <label>Build Id</label>
    <input className="numinput" type="number"
      onChange={(event) => {
        setBuildId(event.target.value);
      }}
    />
   
  </div>
  <label>Build Description</label>
    <input className="informationinput" type="text"
      onChange={(event) => {
        setBuildDescription(event.target.value);
      }}
    />
</Col></Row>
<Row><Col>
  <div style={{ justifyContent: 'center' }} className="">

    <label >build image path</label>
    <input className="informationinput" type="text"
      onChange={(event) => {
        setBuildimagepath(event.target.value);
      }}
    />
  </div>
</Col></Row>
<Row><Col>
  <div style={{ justifyContent: 'center' }} className="">

    <label>build image path2</label>
    <input className="informationinput" type="text"
      onChange={(event) => {
        setBuildimagepath2(event.target.value);
      }}
    />
 
  </div>
  <div>
                      <form onSubmit={onSubmitHandler} >
                        <input type='file' onChange={fileChangeHandler} />
                        <Button type='submit' variant="primary" onClick={()=>{addRecipieprops();
                        addImglink(buildimagepath);}}>
            Save Changes
          </Button>
                      </form>
                    </div>
</Col></Row>



          </div>   </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>
          <input
            className="apizzasearch"
            type="text"
            placeholder="search..."
            onChange={(event) => { setSearchTerm(event.target.value) }} />
          <br />
          <div className="button">
            <button className="" onClick={Utils.changeColordatax}>Not so bright</button>
          </div>
          <br />
          <div id="btnd" className="employees d-flex align-content-start flex-wrap">
         
            {recipieslist.filter((val) => {
              if (searchTerm === "") {
                return val
              } else if (val.recipiename.toLowerCase().includes(searchTerm.toLowerCase())) {
                return (val);
              } else { return null }
            }).map((val) => {
              return (

                <div key={val.id} id="btnd" >


                  <div className="employee">
                    <div className='input'>Recipe name:<br></br><strong className='rtitle'>{val.recipiename}</strong></div>
                    <div>
                    {(val.imglink=== null || val.imglink === "") ? <Button variant="primary" onClick={handleShow}>
        Add Build Page
      </Button>: null
       }
                    </div>
                    
                    {/* <ImgLoader/> */}

                    <div className='input'>id:{val.id}</div>
                    <div id="done" className='input'>
                      <strong className='inputT'></strong>{val.ingediant1num}
                      <br></br>{val.measurement1}
                      <div className='input2'>{val.ingediant1}
                        
                        <button className="" onClick={Utils.changeColordata}>Done</button>
                      </div>
                    </div>
                    <div id="done2" className='input'><strong className='inputT'></strong>{val.ingediant2num}
                      <br></br>{val.measurement2}<div className='input2'>{val.ingediant2}
                        
                        <button className="" onClick={Utils.changeColordata2}>Done</button>
                      </div>
                    </div>
                    <div id="done3" className='input'><strong className='inputT'></strong>{val.ingediant3num}<br></br>{val.measurement3}<div className='input2'>{val.ingediant3}
                      
                      <button className="" onClick={Utils.changeColordata3}>Done</button>
                    </div>
                    </div>
                    <div id="done4" className='input'><strong className='inputT'></strong>{val.ingediant4num}<br></br>{val.measurement4}<div className='input2'>{val.ingediant4}
                      
                      <button className="" onClick={Utils.changeColordata4}>Done</button>
                    </div>
                    </div>

                    { (val.ingediant5num === null || val.ingediant5num === 0) ? "no more ingrediants" :
        <Collapsible trigger="more ingredients">
       
          <div id="done5" className='input'><strong className='inputT'></strong>{val.ingediant5num}<br></br>{val.measurement5}<div className='input2'>{val.ingediant5}
          
                  <button className="Buttoncheck" onClick={Utils.changeColordata5}>Done</button>
            </div>
          </div>
          { (val.ingediant6num === null || val.ingediant6num === 0) ? null :
          <div id="done6" className='input'><strong className='inputT'></strong>{val.ingediant6num}<br></br>{val.measurement6}<div className='input2'>{val.ingediant6}
          
                  <button className="Buttoncheck" onClick={Utils.changeColordata6}>Done</button>
            </div>
          </div>}
          { (val.ingediant7num === null || val.ingediant7num === 0) ? null :
          <div id="done7" className='input'><strong className='inputT'></strong>{val.ingediant7num}<br></br>{val.measurement7}<div className='input2'>{val.ingediant7}
          
                  <button className="Buttoncheck" onClick={Utils.changeColordata7}>Done</button>
            </div>
          </div>}
          { (val.ingediant8num === null || val.ingediant8num === 0) ? null :
          <div id="done8" className='input'><strong className='inputT'></strong>{val.ingediant8num}<br></br>{val.measurement8}<div className='input2'>{val.ingediant8}
          
                  <button className="Buttoncheck" onClick={Utils.changeColordata8}>Done</button>
            </div>
          </div>}
          {(val.ingediant9num === null || val.ingediant9num === 0) ? null :
          <div id="done9" className='input'><strong className='inputT'></strong>{val.ingediant9num}<br></br>{val.measurement9}<div className='input2'>{val.ingediant9}
          
                  <button className="Buttoncheck" onClick={Utils.changeColordata9}>Done</button>
            </div>
          </div>}
          { (val.ingediant10num === null || val.ingediant10num === 0) ? null :
          <div id="done10" className='input'><strong className='inputT'></strong>{val.ingediant10num}<br></br>{val.measurement10}<div className='input2'>{val.ingediant10}
          
                  <button className="Buttoncheck" onClick={Utils.changeColordata10}>Done</button>
            </div>
          </div>}
          {  (val.ingediant11num === null || val.ingediant11num === 0)  ?  null :
           <div id="done11" className='input'><strong className='inputT'></strong>{val.ingediant11num}<br></br>{val.measurement11}<div className='input2'>{val.ingediant11}
          
              <button className="Buttoncheck" onClick={Utils.changeColordata11}>Done</button>
            </div>
          </div> }

        
          { (val.ingediant12num === null || val.ingediant12num === 0) ? null :
          <div id="done12" className='input'><strong className='inputT'></strong>{val.ingediant12num}<br></br>{val.measurement12}<div className='input2'>{val.ingediant12}
          
                  <button className="Buttoncheck" onClick={Utils.changeColordata12}>Done</button>
            </div>
          </div>}
          {/*{ (val.ingediant13num === null || val.ingediant13num === 0) ? null :
          <div id="done13" className='input'><strong className='inputT'></strong>{val.ingediant13num}<br></br>{val.measurement13}<div className='input2'>{val.ingediant13}
          
                  <button className="Buttoncheck" onClick={Utils.changeColordata13}>Done</button>
            </div>
          </div>}
          { (val.ingediant14num === null || val.ingediant14num === 0) ? null :
          <div id="done14" className='input'><strong className='inputT'></strong>{val.ingediant14num}<br></br>{val.measurement14}<div className='input2'>{val.ingediant14}
          
                  <button className="Buttoncheck" onClick={Utils.changeColordata14}>Done</button>
            </div>
          </div>}
          { (val.ingediant15num === null || val.ingediant15num === 0) ? null :
          <div id="done15" className='input'><strong className='inputT'></strong>{val.ingediant15num}<br></br>{val.measurement15}<div className='input2'>{val.ingediant15}
          
                  <button className="Buttoncheck" onClick={Utils.changeColordata15}>Done</button>
            </div>
          </div>}
          { (val.ingediant16num === null || val.ingediant16num === 0) ? null :
          <div id="done16" className='input'><strong className='inputT'></strong>{val.ingediant16num}<br></br>{val.measurement16}<div className='input2'>{val.ingediant16}
          
                  <button className="Buttoncheck" onClick={Utils.changeColordata16}>Done</button>
            </div>
          </div>}
          { (val.ingediant17num === null || val.ingediant17num === 0) ? null :
          <div id="done17" className='input'><strong className='inputT'></strong>{val.ingediant17num}<br></br>{val.measurement17}<div className='input2'>{val.ingediant17}
          
                  <button className="Buttoncheck" onClick={Utils.changeColordata17}>Done</button>
            </div>
          </div>}
          { (val.ingediant18num === null || val.ingediant18num === 0) ? null :
          <div id="done18" className='input'><strong className='inputT'></strong>{val.ingediant18num}<br></br>{val.measurement18}<div className='input2'>{val.ingediant18}
          
                  <button className="Buttoncheck" onClick={Utils.changeColordata18}>Done</button>
            </div>
          </div>}
          { (val.ingediant19num === null || val.ingediant19num === 0) ? null :
          <div id="done19" className='input'><strong className='inputT'></strong>{val.ingediant19num}<br></br>{val.measurement19}<div className='input2'>{val.ingediant19}
          
                  <button className="Buttoncheck" onClick={Utils.changeColordata19}>Done</button>
            </div>
          </div>}
          { (val.ingediant20num === null || val.ingediant20num === 0) ? null :
          <div id="done20" className='input'><strong className='inputT'></strong>{val.ingediant20num}<br></br>{val.measurement20}<div className='input2'>{val.ingediant20}
          
                  <button className="Buttoncheck" onClick={Utils.changeColordata20}>Done</button>
            </div>
          </div>}
          */}
        
        </Collapsible>}{(val.imglink=== null || val.imglink === "") ? null:<div id="done2" className='input'>Image:<Nav.Link as={Link} to={`./${val.imglink}`}>Build Image</Nav.Link></div>
       }
                    <div id="done2" className='input'>Notes:{val.notes}</div>
                    <div id="done2" className='input'>
                      <Collapsible trigger="Update data"> 
                        <input className="button" type="text" placeholder='Recipie name:...' onChange={(event) => { setNewrecipiename(event.target.value); }} />
                        <button onClick={() => { updateRecipiename(val.id) }}>Update</button>

                        <input className="button" type="text" placeholder='notes:...' onChange={(event) => { setNewnotes(event.target.value); }} />
                        <button onClick={() => { updateNotes(val.id) }}>Update</button>
                        <button onClick={() => { confirmDelete(val.id) }}>Delete</button>

                        <label>Times</label>
                        <input className="numinput" type="number"
                          onChange={(event) => {
                            setTimes(event.target.value);
                          }}
                        />
                        <button onClick={() => { newIngediantNumber(val.id) }}>multiply</button>
                        <button onClick={() => { resetIngediantNumber(val.id) }}>divide</button>
                        <button className="buttonbook" onClick={getRecipies}>Reset</button>


                      </Collapsible>

                    </div>






                  </div>
                </div>
              );
            })}
          </div>


          <div className='information' style={{ display: 'flex-column', justifyContent: 'center' }} >
            <Row><Col>
              <div style={{ display: '', justifyContent: 'center' }} className="">

                <label >Recipe name</label>
                <input className="informationinput" type="text"
                  onChange={(event) => {
                    setRecipiename(event.target.value);
                  }}
                />
              </div>
            </Col></Row>
            <Row><Col>
              <div style={{ display: '', justifyContent: 'center' }} className="">

                <label>quantity</label>
                <input className="numinput" type="number"
                  onChange={(event) => {
                    setIngediant1num(event.target.value);
                  }}
                />
                <select onChange={(event) => {
                  setMeasurement1(event.target.value);
                }}>
                  <option value="Cups">Cups</option>
                  <option value="Cup">Cup</option>
                  <option value="fluid oz">fluid oz</option>
                  <option value="dry oz">dry oz</option>
                  <option value="lb">Pounds</option>
                  <option value="Quarts">Quarts</option>
                  <option value="Quart">Quart</option>
                  <option value="Grams">Grams</option>
                  <option value="teaspoon">teaspoon</option>
                  <option value="Tablespoon">Tablespoon</option>
                  <option value="Whole">Whole</option>
                </select>


                <label>ingredient1</label>
                <input className="informationinput" type="text"
                  onChange={(event) => {
                    setIngediant1(event.target.value);
                  }}
                />
              </div>
            </Col></Row>

            <Row><Col>
              <div style={{ display: '', justifyContent: 'center' }} className="">

                <label>quantity</label>
                <input className="numinput" type="number"
                  onChange={(event) => {
                    setIngediant2num(event.target.value);
                  }}
                />
                <select onChange={(event) => {
                  setMeasurement2(event.target.value);
                }}>
                  <option value="Cups">Cups</option>
                  <option value="Cup">Cup</option>
                  <option value="fluid oz">fluid oz</option>
                  <option value="dry oz">dry oz</option>
                  <option value="lb">Pounds</option>
                  <option value="Quarts">Quarts</option>
                  <option value="Quart">Quart</option>
                  <option value="Grams">Grams</option>
                  <option value="teaspoon">teaspoon</option>
                  <option value="Tablespoon">Tablespoon</option>
                  <option value="Whole">Whole</option>
                </select>
                <label>ingredient2</label>
                <input className="informationinput" type="text"
                  onChange={(event) => {
                    setIngediant2(event.target.value);
                  }}
                />
              </div>
            </Col></Row>
            <Row><Col>
              <div style={{ display: '', justifyContent: 'center' }} className="">

                <label>quantity</label>
                <input className="numinput" type="number"
                  onChange={(event) => {
                    setIngediant3num(event.target.value);
                  }}
                />
                <select onChange={(event) => {
                  setMeasurement3(event.target.value);
                }}>
                  <option value="Cups">Cups</option>
                  <option value="Cup">Cup</option>
                  <option value="fluid oz">fluid oz</option>
                  <option value="dry oz">dry oz</option>
                  <option value="lb">Pounds</option>
                  <option value="Quarts">Quarts</option>
                  <option value="Quart">Quart</option>
                  <option value="Grams">Grams</option>
                  <option value="teaspoon">teaspoon</option>
                  <option value="Tablespoon">Tablespoon</option>
                  <option value="Whole">Whole</option>
                </select>
                <label>ingredient3</label>
                <input className="informationinput" type="text"
                  onChange={(event) => {
                    setIngediant3(event.target.value);
                  }}
                />
              </div>
            </Col></Row>
            <Row><Col>
              <div style={{ display: '', justifyContent: 'center' }} className="">

                <label>quantity</label>
                <input className="numinput" type="number"
                  onChange={(event) => {
                    setIngediant4num(event.target.value);
                  }}
                />
                <select onChange={(event) => {
                  setMeasurement4(event.target.value);
                }}>
                  <option value="Cups">Cups</option>
                  <option value="Cup">Cup</option>
                  <option value="fluid oz">fluid oz</option>
                  <option value="dry oz">dry oz</option>
                  <option value="lb">Pounds</option>
                  <option value="Quarts">Quarts</option>
                  <option value="Quart">Quart</option>
                  <option value="Grams">Grams</option>
                  <option value="teaspoon">teaspoon</option>
                  <option value="Tablespoon">Tablespoon</option>
                  <option value="Whole">Whole</option>
                </select>
                <label>ingredient4</label>
                <input className="informationinput" type="text"
                  onChange={(event) => {
                    setIngediant4(event.target.value);
                  }}
                />
              </div>
            </Col></Row>

            <Row><Col>
              <div style={{ display: '', justifyContent: 'center' }} className="">

                <label>quantity</label>
                <input className="numinput" type="number"
                  onChange={(event) => {
                    setIngediant5num(event.target.value);
                  }}
                />
                <select onChange={(event) => {
                  setMeasurement5(event.target.value);
                }}>
                  <option value="Cups">Cups</option>
                  <option value="Cup">Cup</option>
                  <option value="fluid oz">fluid oz</option>
                  <option value="dry oz">dry oz</option>
                  <option value="lb">Pounds</option>
                  <option value="Quarts">Quarts</option>
                  <option value="Quart">Quart</option>
                  <option value="Grams">Grams</option>
                  <option value="teaspoon">teaspoon</option>
                  <option value="Tablespoon">Tablespoon</option>
                  <option value="Whole">Whole</option>
                </select>
                <label>ingredient5</label>
                <input className="informationinput" type="text"
                  onChange={(event) => {
                    setIngediant5(event.target.value);
                  }}
                />
              </div>
            </Col></Row>
            <Collapsible className='Colaps' style={{ display: 'flex', justifyContent: 'center' }} trigger="More Ingrediants!">
              <div className='information' style={{ display: 'flex-col', justifyContent: 'center' }} >
                <Row><Col>
                  <div style={{ display: '', justifyContent: 'center' }} className="">

                    <label>quantity</label>
                    <input className="numinput" type="number"
                      onChange={(event) => {
                        setIngediant6num(event.target.value);
                      }}
                    />
                    <select onChange={(event) => {
                      setMeasurement6(event.target.value);
                    }}>
                      <option value="Cups">Cups</option>
                      <option value="Cup">Cup</option>
                      <option value="fluid oz">fluid oz</option>
                      <option value="dry oz">dry oz</option>
                      <option value="lb">Pounds</option>
                      <option value="Quarts">Quarts</option>
                      <option value="Quart">Quart</option>
                      <option value="Grams">Grams</option>
                      <option value="teaspoon">teaspoon</option>
                      <option value="Tablespoon">Tablespoon</option>
                      <option value="Whole">Whole</option>
                    </select>
                    <label>ingredient6</label>
                    <input className="informationinput" type="text"
                      onChange={(event) => {
                        setIngediant6(event.target.value);
                      }}
                    />
                  </div>
                </Col></Row>
                <Row><Col>
                  <div style={{ display: '', justifyContent: 'center' }} className="">

                    <label>quantity</label>
                    <input className="numinput" type="number"
                      onChange={(event) => {
                        setIngediant7num(event.target.value);
                      }}
                    />
                    <select onChange={(event) => {
                      setMeasurement7(event.target.value);
                    }}>
                      <option value="Cups">Cups</option>
                      <option value="Cup">Cup</option>
                      <option value="fluid oz">fluid oz</option>
                      <option value="dry oz">dry oz</option>
                      <option value="lb">Pounds</option>
                      <option value="Quarts">Quarts</option>
                      <option value="Quart">Quart</option>
                      <option value="Grams">Grams</option>
                      <option value="teaspoon">teaspoon</option>
                      <option value="Tablespoon">Tablespoon</option>
                      <option value="Whole">Whole</option>
                    </select>
                    <label>ingredient7</label>
                    <input className="informationinput" type="text"
                      onChange={(event) => {
                        setIngediant7(event.target.value);
                      }}
                    />
                  </div>
                </Col></Row>
                <Row><Col>
                  <div style={{ display: '', justifyContent: 'center' }} className="">

                    <label>quantity</label>
                    <input className="numinput" type="number"
                      onChange={(event) => {
                        setIngediant8num(event.target.value);
                      }}
                    />

                    <select onChange={(event) => {
                      setMeasurement8(event.target.value);
                    }}>
                      <option value="Cups">Cups</option>
                      <option value="Cup">Cup</option>
                      <option value="fluid oz">fluid oz</option>
                      <option value="dry oz">dry oz</option>
                      <option value="lb">Pounds</option>
                      <option value="Quarts">Quarts</option>
                      <option value="Quart">Quart</option>
                      <option value="Grams">Grams</option>
                      <option value="teaspoon">teaspoon</option>
                      <option value="Tablespoon">Tablespoon</option>
                      <option value="Whole">Whole</option>
                    </select>
                    <label>ingredient8</label>
                    <input className='informationinput' type='text'
                      onChange={(event) => {
                        setIngediant8(event.target.value);
                      }}
                    />
                  </div>
                  {/*     <div>
                       <label>Pictures</label>
                    <input formEncType='multipart/form-data' id='image_input' className='informationinput' type='file' name = 'image'
                      onChange={(event) => {
                        setPicture(event.target.value);
                      }}
                    />
                    </div>
                   
                    <div className="displayimg"></div>
                    */}
                </Col></Row>
                <Row><Col>
                  <div style={{ display: '', justifyContent: 'center' }} className="">

                    <label>quantity</label>
                    <input className="numinput" type="number"
                      onChange={(event) => {
                        setIngediant9num(event.target.value);
                      }}
                    />
                    <select onChange={(event) => {
                      setMeasurement9(event.target.value);
                    }}>
                      <option value="Cups">Cups</option>
                      <option value="Cup">Cup</option>
                      <option value="fluid oz">fluid oz</option>
                      <option value="dry oz">dry oz</option>
                      <option value="lb">Pounds</option>
                      <option value="Quarts">Quarts</option>
                      <option value="Quart">Quart</option>
                      <option value="Grams">Grams</option>
                      <option value="teaspoon">teaspoon</option>
                      <option value="Tablespoon">Tablespoon</option>
                      <option value="Whole">Whole</option>
                    </select>
                    <label>ingredient9</label>
                    <input className="informationinput" type="text"
                      onChange={(event) => {
                        setIngediant9(event.target.value);
                      }}
                    />
                  </div>
                </Col></Row>
                <Row><Col>
                  <div style={{ display: '', justifyContent: 'center' }} className="">

                    <label>quantity</label>
                    <input className="numinput" type="number"
                      onChange={(event) => {
                        setIngediant10num(event.target.value);
                      }}
                    />
                    <select onChange={(event) => {
                      setMeasurement10(event.target.value);
                    }}>
                      <option value="Cups">Cups</option>
                      <option value="Cup">Cup</option>
                      <option value="fluid oz">fluid oz</option>
                      <option value="dry oz">dry oz</option>
                      <option value="lb">Pounds</option>
                      <option value="Quarts">Quarts</option>
                      <option value="Quart">Quart</option>
                      <option value="Grams">Grams</option>
                      <option value="teaspoon">teaspoon</option>
                      <option value="Tablespoon">Tablespoon</option>
                      <option value="Whole">Whole</option>
                    </select>
                    <label>ingredient10</label>
                    <input className="informationinput" type="text"
                      onChange={(event) => {
                        setIngediant10(event.target.value);
                      }}
                    />
                  </div>
                </Col></Row>
                <Row><Col>
                  <div style={{ display: '', justifyContent: 'center' }} className="">

                    <label>quantity</label>
                    <input className="numinput" type="number"
                      onChange={(event) => {
                        setIngediant11num(event.target.value);
                      }}
                    />
                    <select onChange={(event) => {
                      setMeasurement11(event.target.value);
                    }}>
                      <option value="Cups">Cups</option>
                      <option value="Cup">Cup</option>
                      <option value="fluid oz">fluid oz</option>
                      <option value="dry oz">dry oz</option>
                      <option value="lb">Pounds</option>
                      <option value="Quarts">Quarts</option>
                      <option value="Quart">Quart</option>
                      <option value="Grams">Grams</option>
                      <option value="teaspoon">teaspoon</option>
                      <option value="Tablespoon">Tablespoon</option>
                      <option value="Whole">Whole</option>
                    </select>
                    <label>ingredient11</label>
                    <input className="informationinput" type="text"
                      onChange={(event) => {
                        setIngediant11(event.target.value);
                      }}
                    />
                  </div>
                </Col></Row>
                <Row><Col>
                  <div style={{ display: '', justifyContent: 'center' }} className="">

                    <label>quantity</label>
                    <input className="numinput" type="number"
                      onChange={(event) => {
                        setIngediant12num(event.target.value);
                      }}
                    />
                    <select onChange={(event) => {
                      setMeasurement12(event.target.value);
                    }}>
                      <option value="Cups">Cups</option>
                      <option value="Cup">Cup</option>
                      <option value="fluid oz">fluid oz</option>
                      <option value="dry oz">dry oz</option>
                      <option value="lb">Pounds</option>
                      <option value="Quarts">Quarts</option>
                      <option value="Quart">Quart</option>
                      <option value="Grams">Grams</option>
                      <option value="teaspoon">teaspoon</option>
                      <option value="Tablespoon">Tablespoon</option>
                      <option value="Whole">Whole</option>
                    </select>
                    <label>ingredient12</label>
                    <input className="informationinput" type="text"
                      onChange={(event) => {
                        setIngediant12(event.target.value);
                      }}
                    />
                  </div>
                </Col></Row>
              </div>
            </Collapsible>
          </div>

          <label>notes</label>

          <input className="notes" type="text"
            onChange={(event) => {
              setNotes(event.target.value);
            }}
          />
          <Row>
            <Col>
              <button className="button" onClick={addRecipie}>Add  Recipe</button>
            </Col>

          </Row>


        </div>

      </Container>



    </div>

  );
}

export default Recipes;
