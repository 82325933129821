import Register from './components/Register';
import Login from './components/Login';
import Home from './components/Home';
import Layout from './components/Layout';
import Editor from './components/Editor';
import Admin from './components/Admin';
import Missing from './components/Missing';
import Unauthorized from './components/Unauthorized';
import Recipes from './components/Recipes';

import LinkPage from './components/LinkPage';
import RequireAuth from './components/RequireAuth';

import { Routes, Route } from 'react-router-dom';
import Recipesearch from './routes/recipesearch';
import Map from './routes/googlemap';
import PCerie from './routes/pcpricecalc';
import WGMSjames from './routes/wgpricecalc';
import 'bootstrap/dist/css/bootstrap.min.css';

import Locationlock from './components/404';
import ApizzaRecipes from './components/apizza';
import Workscheduler from './components/ApizzaImages/workschedule';
import MasterBuild from './components/ApizzaImages/masterBuild';
import RecipesImagebuild from './components/RecipesImagebuild';
const ROLES = {
  'User': 2001,
  'Editor': 1984,
  'Admin': 5150,
  'DungeonMaster': 2020,
}

function App() {

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="locationlock" element={<Locationlock />} />
        <Route path="linkpage" element={<LinkPage />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        {/* we want to protect these routes */}
        
        <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
          <Route path="workschedule" element={<Workscheduler />} />
              
              <Route path="apizza/:imglink" element={<MasterBuild 
                 
              />} />
               <Route path="recipes/:imglink" element={<RecipesImagebuild 
                 
                 />} />
        
        </Route>

        
        <Route element={<RequireAuth allowedRoles={[ROLES.DungeonMaster, ROLES.Editor]} />}>
          <Route path="editor" element={<Editor />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.DungeonMaster ]} />}>
          <Route path="admin" element={<Admin />} />
        </Route>
    
        <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
          <Route path="/" element={<Home />} />
          <Route path="recipes" element={<Recipes />} />
          <Route path="recipesearch" element={<Recipesearch />} />
          <Route path="map" element={<Map />} />
          <Route path="apizza" element={<ApizzaRecipes />} />
          <Route path="pcerie" element={<PCerie />} />
          <Route path="wgmsjames" element={<WGMSjames />} />
        </Route>


        {/* catch all */}
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;