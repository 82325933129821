import React, {useState} from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import  { Tableau } from './test';
import  { Tableau2 } from './pc_chart';
import apizzalogo from '../components/apizzalogo.png';

const containerStyle = {
  width: '100vw',
  height: '100vh'
};
const myLatLng = {
  lat:43.04516677500132,
  lng: -76.10151737324254
};


const locations = [
  { name: "Tops Shop City",
  location: { 
    lat: 43.07396796178061,
    lng:  -76.11864442442797
  },
},
  { name: "Tops 1st St.",
  location: { 
    lat: 43.070656207978345, 
    lng: -76.14858892513074
  },
},
  { name: "Trader Joe's",
  location: { 
    lat: 43.04631084666639,
    lng:  -76.07040188926021
  },
},
  { name: "Price Chopper",
  Tableau: <Tableau2 />,
    location: { 
      lat: 43.05483098428578, 
      lng:  -76.10236097945801
    },
  },
  { name: "Wegmans James St.",
  Tableau: <Tableau />,
  location: {
    lat:43.068465847046326, 
    lng:-76.08659782587175},},
    { 
      
      name: "Apizza Reginal!",
      special: "Calamari Pizza!",
      specialimg: <img src={apizzalogo} alt=""></img>,
      specialinfo: " Hot Tomato oil with calamari herbs & Garlic on pizza",
  location: {
    lat:43.05241614306171, 
    lng: -76.11448480243963},},
];
const id = ["4b8f659271fb6001"]
const InitMap=({loginStatus})=> {

  

 
  const [ selected, setSelected ] = useState({});
  const onSelect = item => {
    setSelected(item);
  }

  return (
    <div className=''>
    <LoadScript
      mapIds={id}
      googleMapsApiKey="AIzaSyDnSRIHZg57MB9AeCs44RFtbpRUZ95RUgo"
    >
       
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={myLatLng}
        zoom={11}
        options={{ mapId: "4b8f659271fb6001" }}
      >
       
        {     locations.map(item => {
              return (
                
              <Marker key={item.name} position={item.location} onClick={() => onSelect(item)}/>
              )
            })
  }
  
  {
     selected.location && 
     (
       <InfoWindow className="infotable"
       position={selected.location}
       clickable={true}
       onCloseClick={() => setSelected({})}
     >
       <div >
       <p>{selected.name}</p>
       <p>{selected.specialimg}</p>
       <p><strong>{selected.special}</strong></p>
       <p>{selected.Tableau}</p>
      
      <strong>Ingredients</strong>
      <div >
      
      {selected.specialinfo}
      </div>
     </div>
       
     </InfoWindow>)
  }

        <></>
        
      </GoogleMap>
    </LoadScript>
    
    </div>
  )
  
  
}


export default React.memo(InitMap)