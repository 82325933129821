import { Row, Col, Container } from 'react-bootstrap';
import { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";



const { format } = require('date-fns');



const Workscheduler = () => {
  const dateTime = `${format(new Date(), 'EEEE')}`;


    const axiosPrivate = useAxiosPrivate();

const [name, setName] = useState("");
const [timein, setTimein] = useState("");
const [timein2, setTimein2] = useState("");
const [timein3, setTimein3] = useState("");
const [timein4, setTimein4] = useState("");
const [timein5, setTimein5] = useState("");
const [timein6, setTimein6] = useState("");
const [timein7, setTimein7] = useState("");



const [timeout, setTimeout] = useState("");
const [timeout2, setTimeout2] = useState("");
const [timeout3, setTimeout3] = useState("");
const [timeout4, setTimeout4] = useState("");
const [timeout5, setTimeout5] = useState("");
const [timeout6, setTimeout6] = useState("");
const [timeout7, setTimeout7] = useState("");

const [schedule, setSchedule] = useState([]);



const addSchedule = async () => {
    
    await axiosPrivate.post("/apizza/schedule", {
      mode: 'cors',
      
      name: name,
      timein: timein,
      timeout: timeout,
      timein2: timein2,
      timeout2: timeout2,
      timein3: timein3,
      timeout3: timeout3,
      timein4: timein4,
      timeout4: timeout4,
      timein5: timein5,
      timeout5: timeout5,
      timein6: timein6,
      timeout6: timeout6,
      timein7: timein7,
      timeout7: timeout7,
     
      
    }).then(() => {
      setSchedule([
        ...schedule,
        {
          mode: 'cors',
          name: name,
          
      timein: timein,
      timeout: timeout,
      timein2: timein2,
      timeout2: timeout2,
      timein3: timein3,
      timeout3: timeout3,
      timein4: timein4,
      timeout4: timeout4,
      timein5: timein5,
      timeout5: timeout5,
      timein6: timein6,
      timeout6: timeout6,
      timein7: timein7,
      timeout7: timeout7,
     
        },
      ]);
    });
  };
  
  const getAllschedules = async  () => {
    setTrig("week");
   await axiosPrivate.get("/apizza/schedule").then((response) => {
      setSchedule(response.data);
      window.scrollTo(0, 1000);
    });
  };
  
  const [trig, setTrig] = useState("");
  const getDayschedule = async  () => {
    
    setTrig("day");
    await axiosPrivate.get("/apizza/schedule/day").then((response) => {
       setSchedule(response.data);
       window.scrollTo(0, 1000);

     });
   };
 
 
    return(
        <div>
          <button className="buttonbook" onClick={addSchedule}>Add Schedule</button>
          <button className="buttonbook" onClick={getAllschedules}>Get Schedule for Week</button>
          <button className="buttonbook" onClick={getDayschedule}>Get Schedule for the Day</button>
        <div>
            <Container>
              
                <Row>
                    <Col>
                    
                    <label>Employee</label>
               
                    <select onChange={(event) => {
                      setName(event.target.value);
                    }}>
                      <option value="Marty">Marty</option>
                      <option value="Dave">Dave</option>
                      <option value="Eric">Eric</option>
                      <option value="Jake">Jake</option>
                      <option value="Kenneth">Kenneth</option>
                      <option value="Eddie">Eddie</option>
                      <option value="Adam">Adam</option>
                      <option value="Jody">Jody</option>
                      <option value="Alex">Alex</option>
                      <option value="Pete">Pete</option>
                      <option value="Micky">Micky</option>
                    </select>
                    </Col>
                    <Col>
                    <h2>Monday</h2>
                    <input className="numinput" type="string"
                  onChange={(event) => {
                    setTimein(event.target.value);
                  }}
                /> <input className="numinput" type="string"
                onChange={(event) => {
                  setTimeout(event.target.value);
                }}
              />
                    </Col>
                    <Col>
                    <h2>Tuesday</h2>
                    <input className="numinput" type="string"
                  onChange={(event) => {
                    setTimein2(event.target.value);
                  }}
                /> <input className="numinput" type="string"
                onChange={(event) => {
                  setTimeout2(event.target.value);
                }}
              />
                    </Col>
                    <Col>
                    <h2>Wendsday</h2>
                    <input className="numinput" type="string"
                  onChange={(event) => {
                    setTimein3(event.target.value);
                  }}
                /> <input className="numinput" type="string"
                onChange={(event) => {
                  setTimeout3(event.target.value);
                }}
              />
                    </Col>
                    <Col>
                    <h2>Thursday</h2>
                    <input className="numinput" type="string"
                  onChange={(event) => {
                    setTimein4(event.target.value);
                  }}
                /> <input className="numinput" type="string"
                onChange={(event) => {
                  setTimeout4(event.target.value);
                }}
              />
                    </Col>
                    <Col>
                    <h2>Friday</h2>
                    <input className="numinput" type="string"
                  onChange={(event) => {
                    setTimein5(event.target.value);
                  }}
                /> <input className="numinput" type="string"
                onChange={(event) => {
                  setTimeout5(event.target.value);
                }}
              />
                    </Col>
                    <Col>
                    <h2>Saturday</h2>
                    <input className="numinput" type="string"
                  onChange={(event) => {
                    setTimein6(event.target.value);
                  }}
                /> <input className="numinput" type="string"
                onChange={(event) => {
                  setTimeout6(event.target.value);
                }}
              />
                    </Col>
                    <Col>
                    <h2>Sunday</h2>
                    <input className="numinput" type="string"
                  onChange={(event) => {
                    setTimein7(event.target.value);
                  }}
                /> <input className="numinput" type="string"
                onChange={(event) => {
                  setTimeout7(event.target.value);
                }}
              />
                    </Col>
                    

                </Row>
              
            </Container>
            <h1>Schedule</h1>

            {schedule.map((val)=> {  return (
                (trig==='day' && dateTime==='Monday') 
              ?<div>{val.name} : <strong>{dateTime} </strong>{val.timein}- {val.timeout}</div>
              : (trig==='day' && dateTime==='Tuesday')
              ?<div>{val.name} : <strong>{dateTime} </strong>{val.timein2}- {val.timeout2}</div>
              : (trig==='day' && dateTime==='Wednesday')
              ? <div>{val.name} : <strong>{dateTime} </strong>{val.timein3}- {val.timeout3}</div>
              : (trig==='day' && dateTime==='Thursday')
              ?<div>{val.name} <strong>{dateTime} </strong>{val.timein4}- {val.timeout4}</div>
              : (trig==='day' && dateTime==='Friday')
              ? <div>{val.name} : <strong>{dateTime} </strong>{val.timein5}- {val.timeout5}</div>
              : (trig==='day' && dateTime==='Saturday')
              ?<div>{val.name} : <strong>{dateTime} </strong>{val.timein6}- {val.timeout6}</div>
              : (trig==='day' && dateTime==='Sunday')
              ?<div>{val.name} : <strong>{dateTime} </strong>{val.timein7}- {val.timeout7}</div>
              : (trig === 'week')
              ?<div> 
                <li className='schedule'> <Col>{val.name} : <strong>Monday </strong>{val.timein}- {val.timeout}  <strong>Tuesday</strong>{val.timein2}-{val.timeout2}  <strong>Wendsday</strong> {val.timein3}-{val.timeout3}  
                <strong>Thursday</strong> {val.timein4}-{val.timeout4}   <strong>Friday</strong>{val.timein5}-{val.timeout5}   <strong>Saturday</strong>{val.timein6}-{val.timeout6}  <strong>Sunday</strong> {val.timein7}-{val.timeout7}</Col></li>
              </div>
              :<div>something is wrong with schedule</div>
                
              

              )})}
            </div>
        </div>
  
  
        )
};

export default Workscheduler