import '../App.css';
import { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Collapsible from 'react-collapsible';
import { Row, Col, Container } from 'react-bootstrap'
import useAxiosPrivate from "../hooks/useAxiosPrivate";

function Productform() {

    const axiosPrivate = useAxiosPrivate();

    const wgjproductstory = "Hello, this is where we keep track of the price of products at Wegmans James St. location. Click show products to see whats already there, and then you can search by product name! If you dont see the product you're looking for, help us out. The next time you go you can add the product to the data base right from the store! Now others have the information just like you! We would like to incorprate the ability to create an estimated total of your planned shopping trip so you can plan which items to get from where with our map, and save the lists for each particular store on that stores products page. Then add your fun Recipes from your cooking adventures!"

    const [searchTerm, setSearchTerm] = useState('');
    const [product, setProduct] = useState("");
    const [brand, setBrand] = useState("");
    const [price, setPrice] = useState(0);
    const [quantity, setQuantity] = useState(0);


    const [productlist, setProductlist] = useState([]);

    const [newprice, setNewprice] = useState(0);
    const [newproduct, setNewproduct] = useState(0);


    const addProduct = async () => {
        await axiosPrivate.post("/wgJinventory", {
            mode: 'cors',
            product: product,
            quantity: quantity,
            price: price,
            brand: brand,
        }).then(() => {
            setProductlist([
                ...productlist,
                {
                    mode: 'cors',
                    product: product,
                    quantity: quantity,
                    price: price,
                    brand: brand,
                },
            ]);
        });
    };
    const getProducts = async  () => {
        await axiosPrivate.get("/wgJinventory", {
            mode: 'cors',
        }).then((response) => {
            setProductlist(response.data);
        });
       };
    



    const deleteProduct = async (id) => {

        await axiosPrivate.delete(`/wgJinventory/${id}`, {
            id: id,
        }).then((response) => {
            setProductlist(
                productlist.filter((val) => {
                    return val.id !== id;
                }));
        }
        );
    };




    const updateProduct = async (id) => {
        await axiosPrivate.put("/wgJinventory/productname", {
            mode: 'cors',
            product: newproduct,
            id: id,
        }).then(
            (response) => {
                setProductlist(productlist.map((val, key) => {
                    return val.id === id ? {

                        product: newproduct,
                        id: val.id,
                        quantity: val.quantity,
                        price: val.price,
                        brand: val.brand,
                    } : val
                }));
            }
        );
    };


    const updatePrice = async (id) => {
        await axiosPrivate.put("/wgJinventory/productprice", {
            mode: 'cors',
            price: newprice,
            id: id,
        }).then(
            (response) => {
                setProductlist(productlist.map((val, key) => {
                    return val.id === id ? {
                        product: val.product,
                        id: val.id,
                        quantity: val.quantity,
                        brand: val.brand,
                        price: newprice,
                    } : val
                }));
            }
        );
    };

    const changeColordata = () => {
        const btnd = document.getElementById('btnd');

        if (btnd.style.backgroundColor === 'silver') {
            btnd.style.backgroundColor = '#a0eeee';

            btnd.style.color = 'black';
        } else {
            btnd.style.backgroundColor = 'silver';

            btnd.style.color = 'black';

        }

    }

    return (
        <div id="btn" style={{ display: '', justifyContent: 'center' }} className="App2">

            <div>
                <p className='Paragraph'>{wgjproductstory}</p>
            </div>

            <div className="information">

                <label>product</label>
                <input type="text"
                    onChange={(event) => {
                        setProduct(event.target.value);
                    }}
                />
                <label>quantity</label>
                <input type="text"
                    onChange={(event) => {
                        setQuantity(event.target.value);
                    }}
                />
                <label>price</label>
                <input type="text"
                    onChange={(event) => {
                        setPrice(event.target.value);
                    }}
                />
                <label>Brand</label>
                <input type="text"
                    onChange={(event) => {
                        setBrand(event.target.value);
                    }}
                />
                <Container>
                    <Row className="row"><Col>
                        <button className="button" onClick={addProduct}>Add Product</button>
                    </Col>
                        <Col>
                            <button className="button" onClick={getProducts}>Inventory</button>
                        </Col></Row>
                </Container>


                <input
                    type="text"
                    placeholder="search..."
                    onChange={(event) => { setSearchTerm(event.target.value) }} />
                <div>
                    <button onClick={changeColordata}>Not so bright please</button>
                </div>

                <div id="btnd" className="employees d-flex align-content-start flex-wrap">
                    {productlist.filter((val) => {
                        if (searchTerm === "") {
                            return val
                        } else if (val.product.toLowerCase().includes(searchTerm.toLowerCase())) {
                            return (val);
                        } else { return null }
                    }).map((val) => {
                        return (
                            <div className="employee">
                                <code><li className='input'>Product:{val.product}</li></code>
                                <code><li className='input'>Id:{val.id}</li></code>
                                <code><li className='input'>Quantity:{val.quantity}</li></code>
                                <code><li className='input'>Price:{val.price}</li></code>
                                <code><li className='input'>Brand:{val.brand}</li></code>
                                <div className='input'>
                                    <Collapsible trigger="Update data">
                                        <input className="button" type="text" placeholder='Product:' onChange={(event) => { setNewproduct(event.target.value); }} />
                                        <button onClick={() => { updateProduct(val.id) }}>Update</button>

                                        <input className="button" type="text" placeholder='Price:' onChange={(event) => { setNewprice(event.target.value); }} />
                                        <button onClick={() => { updatePrice(val.id) }}>Update</button>




                                    </Collapsible>
                                </div>
                                <button onClick={() => { deleteProduct(val.id) }}>Delete</button>


                            </div>
                        );
                    })}
                </div>
            </div>

        </div>

    );
}

export default Productform;